import Head from "next/head";
import React, { ReactElement } from "react";
import { useRouter } from "next/router";
interface Props {
    description?: string;
    title?: string;
    keyWords?: string;
    image?: string;
    url?: string;
    type?: string;
    noIndex?: boolean;
}
const locale = process.env.NEXT_PUBLIC_DEFAULT_LANG;
const domainName = process.env.NEXT_PUBLIC_X_DOMAIN;
export default function MetaTags(props: Props): ReactElement {
    const {
        title,
        description,
        image,
        keyWords,
        url,
        type,
        noIndex = false,
    } = props;
    const query = useRouter();
    return (
        <Head>
            <title>{title ?? "several.com"}</title>
            <link
                rel="canonical"
                href={`https://${
                    process.env.NEXT_PUBLIC_DEFAULT_LANG !== "en"
                        ? `${process.env.NEXT_PUBLIC_DEFAULT_LANG as string}.`
                        : ""
                }${domainName as string}${
                    url
                        ? url.replace("index", "")
                        : query.asPath.replace("index", "")
                }`}
            />
            <meta name="title" content={title ?? "several.com"} />
            <meta name="description" content={description ?? "several.com"} />
            <meta name="keywords" content={keyWords ?? "several.com"} />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <meta name="MobileOptimized" content="width" />
            <meta name="HandheldFriendly" content="true" />
            <meta name="referrer" content="origin" />
            {noIndex && <meta name="Robots" content="noindex,nofollow" />}
            <meta
                name="rights"
                content={`©${new Date().getFullYear()} Several.com. All rights reserved.`}
            />
            <meta itemProp="name" content={title ?? "several.com"} />
            <meta
                itemProp="description"
                content={description ?? "several.com"}
            />
            <meta
                itemProp="image"
                content={
                    image ??
                    `https://${domainName as string}/placeholder_several.jpg`
                }
            />

            <meta property="og:type" content={type ?? "website"} />
            <meta property="og:title" content={title ?? "several.com"} />
            <meta
                property="og:url"
                content={`https://${domainName as string}${
                    url ? url : query.asPath
                }`}
            />
            <meta property="og:description" content={description ?? ""} />
            <meta
                property="og:image"
                content={
                    image ??
                    `https://${domainName as string}/placeholder_several.jpg`
                }
            />
            <meta
                property="og:image:url"
                content={
                    image ??
                    `https://${domainName as string}/placeholder_several.jpg`
                }
            />
            <meta property="og:site_name" content="Several.com" />
            <meta property="og:locale" content={locale ?? "en"} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta
                property="twitter:url"
                content={`https://${domainName as string}${
                    url ? url : query.asPath
                }`}
            />
            <meta property="twitter:title" content={title ?? "several.com"} />
            <meta
                property="twitter:description"
                content={description ?? "several.com"}
            />
            <meta
                property="twitter:image"
                content={
                    image ??
                    `https://${domainName as string}/placeholder_several.jpg`
                }
            />
        </Head>
    );
}
