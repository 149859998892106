import React, { ReactElement, useState, useEffect, Dispatch } from "react";
import { addFieldAtom, formsAtom } from "@store/formStore";
import { useAtom } from "jotai";
import styles from "./styles.module.scss";
import { Field, FormActionTypes } from "@genericTypes/sharedTypes";
import { UseFormRegister } from "react-hook-form";
import { AllForms } from "@genericTypes/form-store-types";
import { useDispatch, useFormState } from "../formReducer/FormReducer";

export default function Radio({
    field,
    register,
    required,
    action,
    formId,
    setFieldChanged,
    questionAsTitle,
    isFirstStep,
}: {
    field: Field;
    register: UseFormRegister<{}>;
    required: boolean;
    action: (data?: any) => void;
    formId: number;
    setFieldChanged: Dispatch<React.SetStateAction<boolean>>;
    questionAsTitle: boolean | undefined;
    isFirstStep: boolean;
}): ReactElement {
    const [, setForm] = useAtom(addFieldAtom);
    const [form] = useAtom<AllForms>(formsAtom);
    const [selected, setSelected] = useState({});

    const dispatch = useDispatch();
    const [formState] = useFormState();
    const { formErrors } = formState;

    const onLoad = () => {
        if (form && form[formId] && form[formId]?.data[field.origin.codeName]) {
            setSelected({
                value: form[formId].data[field.origin.codeName].value,
            });
        }
    };

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <div className="w-full cursor-pointer">
            <div
                className={
                    isFirstStep
                        ? `mb-3 !font-extrabold text-center ${
                              questionAsTitle ? "text-[23px]" : "text-lg"
                          }`
                        : questionAsTitle
                        ? `text-[23px] font-extrabold mb-[2rem] flex justify-center`
                        : `mb-3 flex`
                }
            >
                <label>
                    {field?.label ??
                        field.origin.label ??
                        field?.niceName ??
                        field.origin.niceName}
                </label>
            </div>
            <div className="w-full max-w-[320px] sm:max-w-[91%] m-auto">
                {field?.origin.defaultValues?.options.map((option, index) => (
                    <label
                        key={option.label}
                        htmlFor={`${option.value}`}
                        className={`${styles.option} relative cursor-pointer flex items-center justify-center`}
                        id={`${
                            //@ts-ignore
                            selected?.value === `${option.value}`
                                ? `${styles.selected}`
                                : ""
                        }`}
                    >
                        <input
                            className={`invisible absolute`}
                            type="radio"
                            value={option.value}
                            id={`${option.value}`}
                            {...register(field.origin.codeName as never, {
                                required,
                            })}
                            onClickCapture={(e) => {
                                // if (shouldSplit && setModalVisibility)
                                //     setModalVisibility(true);
                                setFieldChanged(true);
                                dispatch({
                                    type: FormActionTypes.SET_ERRORS,
                                    payload: {},
                                });
                                const { value } = e.currentTarget;
                                setSelected({
                                    value: value,
                                });
                                setForm({
                                    codeName: field.origin.codeName,
                                    value: value,
                                    formId: formId,
                                });
                            }}
                            onClick={action}
                        />
                        <label
                            key={index}
                            htmlFor={`${option.value}`}
                            className={`w-full flex gap-4 cursor-pointer ${
                                option?.icon
                                    ? ""
                                    : "max-w-full items-center justify-center"
                            }`}
                        >
                            {option?.icon && (
                                <span className="w-[30px] flex items-center justify-center">
                                    <img
                                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                        src={option?.icon?.fullPath}
                                        alt={option.label as string}
                                        className={`${styles.imgStyle} h-7 w-auto brightness-0`}
                                    />
                                </span>
                            )}
                            <span
                                className="text-[16px] flex items-center"
                                style={{ transform: "translateY(1px)" }}
                            >
                                {option.label}
                            </span>
                        </label>
                    </label>
                ))}
                <div className="flex flex-col">
                    {(field.note || field.origin.note) && (
                        <div className={`flex ${styles["note"]}`}>
                            {field.note ?? field.origin.note}
                        </div>
                    )}
                    {formErrors && formErrors?.[field.origin.codeName] && (
                        <span
                            style={{
                                display: "flex",
                                color: "red",
                                fontSize: "12px",
                            }}
                        >
                            {formErrors?.[field.origin.codeName]?.message ||
                                "Field is required"}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}
