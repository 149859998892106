import Spinner from "@components/shared/spinner";
import { FormErrors } from "@genericTypes/form-store-types";
import {
    ErrorsAfterSubmit,
    ErrorsFieldsAfterSubmitData,
} from "@genericTypes/sharedTypes";
import React, { ReactElement, useState } from "react";
import styles from "../../modal/formModal/styles.module.scss";
import RenderField from "@components/shared/form/renderField/render-field";
import { useFormState } from "../formReducer/FormReducer";

const ErrorsFieldsAfterSubmit = ({
    stepsThatHaveErrors,
    allErrorsFields,
    register,
    setValue,
    watch,
    inputAction,
    formErrors,
    setError,
}: ErrorsFieldsAfterSubmitData) => {
    const [formState] = useFormState();
    const { formResponse, buttonLoading } = formState;
    const [, setFieldChanged] = useState(false);
    let errorsObj: FormErrors = {};

    function setErrorsForForm() {
        for (let i = 0; i < allErrorsFields.length; i++) {
            errorsObj = {
                ...errorsObj,
                [allErrorsFields[i].codeName]: {
                    codeName: allErrorsFields[i].codeName,
                    message: allErrorsFields[i].reason,
                },
            };
        }
    }

    function renderField(errorField: ErrorsAfterSubmit): ReactElement {
        setErrorsForForm();
        const steps = formResponse?.steps;
        if (steps) {
            for (let i = 0; i < steps?.length; i++) {
                const fields = steps[i]?.fields;
                for (let j = 0; j < fields.length; j++) {
                    if (fields[j].origin.codeName === errorField.codeName) {
                        stepsThatHaveErrors.add(i);
                        return (
                            <RenderField
                                fld={fields[j]}
                                formId={formResponse?.id}
                                stateErrors={errorsObj}
                                errorsFromValidation={true}
                                register={register}
                                setValue={setValue}
                                watch={watch}
                                inputAction={inputAction}
                                setFieldChanged={setFieldChanged}
                                key={fields[j].origin.codeName}
                                isSingleField={false}
                                hasTitle={false}
                                isFirstStep={false}
                                formErrors={formErrors}
                                setError={setError}
                            />
                        );
                    }
                }
            }
        }
        return <></>;
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-col items-center w-full h-fit bg-[#fff6f6] text-[#ab6261] p-4 rounded-[10px] border border-solid border-[#ab6261] mb-6">
                <h1 className="text-[16px] sm:text-[21px] font-bold">
                    Your Submission Has a Few Errors
                </h1>
                <p className="text-[11px] sm:text-[17px]">
                    You are almost done! Please correct the following fields:
                </p>
            </div>
            {allErrorsFields?.map((error) => renderField(error))}
            <div>
                <button
                    className={`${styles["nextBtn"]}`}
                    // onClick={() => inputAction(false)}
                    type="submit"
                    disabled={buttonLoading}
                    id="submitBtn"
                >
                    {buttonLoading ? (
                        <Spinner size={25} color="white" success={false} />
                    ) : (
                        "Submit"
                    )}
                </button>
            </div>
        </div>
    );
};

export default ErrorsFieldsAfterSubmit;
