import { gql } from "graphql-request";

export const SITEMAP_GET_ALL_DOMAIN_OFFERS = gql`
    query sitemapGetAllDomainOffers($domainId: Int) {
        allDomainOffers(domainId: $domainId) {
            offers(isActive: true, isDeleted: false) {
                id
                title
                name
                categories {
                    id
                }
                url(isActive: true, isDeleted: false) {
                    isMain
                    main {
                        url
                    }
                    url
                }
                slug
                translations {
                    key
                    value
                    language
                }
            }
        }
    }
`;
