import { gql } from "graphql-request";
export const ALL_POSTS_WRITERS = gql`
    query allPostsWriters(
        $limit: [Int]
        $offset: [Int]
        $domainId: Int
        $userId: String
    ) {
        allPosts(
            limit: $limit
            offset: $offset
            published: true
            isDeleted: false
            domainId: $domainId
            userId: $userId
            orderByDesc: createdAt
        ) {
            id
            title
            type {
                label
            }
            createdAt
            translations {
                key
                value
                language
            }
            image {
                fullPath
            }
            tags {
                name
            }
            url {
                id
                title
                language
                isActive
                url
                isMain
                main {
                    url
                }
            }
        }
    }
`;
