import { gql } from "graphql-request";
export const ALL_URL = gql`
    query allUrls($model: String, $modelId: String) {
        allUrls(
            model: $model
            modelId: $modelId
            isDeleted: false
            isActive: true
        ) {
            model
            modelId
            isMain
            language
            title
            url
            main {
                url
                redirectionStatusCode
            }
        }
    }
`;
