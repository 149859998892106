import { gql } from "graphql-request";
type operation = {
    operation: string;
    value: string | string[];
};
type translationKeys = {
    translationKeys: operation[];
};

export const DOMAIN_INFO = (translationKeys: translationKeys) => {
    const translation = (lang: string) =>
        translationKeys.translationKeys.map((k: operation, index) => {
            const allKeys = Array.isArray(k.value)
                ? k.value.map((kValue: string) => `"${kValue}"`)
                : k.value;
            return `Translation_${index} : allTranslations(language: "${lang}",${
                k.operation
            }:${
                k.operation === "keyIn" && Array.isArray(allKeys)
                    ? `[${allKeys.join(",")}]`
                    : `"${k.value as string}"`
            },modelId: ${
                process.env.NEXT_PUBLIC_NODE_ENV === "development" ? 304 : 2113
            },modelType: "domains") {
                key
                value
            }\n`;
        });

    return gql`
{
    domain(name: "several.com", isDeleted: false, isActive: true) {
        id
        name
        title
        description
        logoId
        faviconId
        secondaryLogoId
        gtmId
        type
        googleSiteVerificationCode
        facebookSiteVerificationCode
        isActive
        userId
        niceName
        analyticsCodeId
        isInspectorEnabled
        enableJornayaId
        enableTrustedForm
        clickyCodeId
        twitterPixel
        translations {
            key
            value
            language
        }
        metatags {
            key
            value
        }
        menus {
            id
            name
            slug
            links(orderBy: order) {
                id
                title
                link
                parentId
                    translations {
                        key
                        value
                        language
                    }
            }
        }
    }
    ${translation(
        (process.env.NEXT_PUBLIC_DEFAULT_LANG as string) ?? "en",
    ).join("")}
    }`;
};
