import CheckIcon from "@components/svg/checkIcon";
import React, { ReactElement, useState } from "react";
import ButtonArrow from "../../buttonArrow";
import StarIcon from "@components/svg/starIcon";
import styles from "./styles.module.scss";

export interface ClickOffer {
    title: string;
    bulletedDescription: string[];
    logoUrl: string;
    brandName: string;
    slug: string;
    revenue: number | undefined;
}

export default function InsuranceOffer({
    item,
    index,
    state,
    category,
    primaryColor,
    textColor,
    btnLabel,
}: {
    item: ClickOffer;
    index: number;
    state?: string;
    category?: string;
    primaryColor?: string;
    textColor?: string;
    btnLabel?: string;
}): ReactElement {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(true);
    };

    return (
        <div className={`${styles["listing-block"]}`}>
            {index === 0 && (
                <div
                    className={styles["order"]}
                    style={{ backgroundColor: primaryColor }}
                >
                    {<StarIcon />}
                </div>
            )}
            <div className={styles["logo"]}>
                <img src={item.logoUrl} alt="logo" />
            </div>

            <div className={styles["content"]}>
                <h2
                    className="mb-4 font-bold lg:text-lg"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                    style={{ color: textColor }}
                ></h2>

                <div className={styles["features"]}>
                    <ul className="flex flex-col" style={{ color: textColor }}>
                        {item.bulletedDescription.map((feature, idx) => (
                            <li
                                key={idx}
                                className="text-sm font-light mb-2 flex items-start"
                            >
                                <span className="children:h-3 children:w-3">
                                    <CheckIcon
                                        className="me-4"
                                        fill={primaryColor}
                                    />
                                </span>

                                <span
                                    className=""
                                    dangerouslySetInnerHTML={{
                                        __html: feature,
                                    }}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div
                className={`${styles["cta"]} ${
                    clicked ? styles["clicked"] : ""
                }`}
                style={{ textAlign: "center" }}
            >
                {item.revenue && (
                    <span className="mb-1 block">${item.revenue}</span>
                )}
                <a
                    target="_blank"
                    onClick={handleClick}
                    href={`/offer/${item.slug}&isExternalOffer=true`}
                    rel="noreferrer"
                    style={{
                        backgroundColor: primaryColor,
                    }}
                >
                    {!clicked ? (
                        <span className={styles["main"]}>
                            {btnLabel ?? "view deal"}
                            <ButtonArrow />
                        </span>
                    ) : (
                        <span className={styles["check"]}>
                            <CheckIcon />
                        </span>
                    )}
                </a>
                {index === 0 && (
                    <span className={styles["cta-state"]}>
                        {state} Best {category}
                    </span>
                )}
            </div>
        </div>
    );
}
