/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AllForms } from "@genericTypes/form-store-types";
import {
    AddressComponent,
    AddressInfoResponse,
    ExpandableFieldValue,
    FormActionTypes,
    FormattedAddressComponent,
    InputProp,
} from "@genericTypes/sharedTypes";
import { handleFieldMasking } from "@helpers/formHelpers/form-masking";
import {
    addFieldAtom,
    formsAtom,
    removeErrorByCodeName,
} from "@store/formStore";
import { useAtom } from "jotai";
import React, { ReactElement, useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { getAddressInfo } from "src/api/searchAddress";
import { useDispatch, useFormState } from "../../formReducer/FormReducer";
import styles from "../../input/styles.module.scss";
import formStyles from "../../style.module.scss";
import ExpandableTab from "../expandableTab";

const StreetAddress = ({
    field,
    formId,
    setFieldChanged,
    questionAsTitle,
    isFirstStep,
}: InputProp): ReactElement => {
    const [form] = useAtom<AllForms>(formsAtom);
    const [, setForm] = useAtom(addFieldAtom);
    const [, removeError] = useAtom(removeErrorByCodeName);
    const dispatch = useDispatch();
    const [formState] = useFormState();
    const { formErrors, expandableField } = formState;

    const fillFieldValue = (): ExpandableFieldValue | null => {
        if (form && form[formId] && form[formId]?.data["zipCode"]) {
            // set value is a function provided from use form hook.
            return {
                value: form[formId]?.data["zipCode"].value as string,
                displayValue: form[formId]?.data["zipCode"]
                    .displayLabel as string,
            };
        }
        return null;
    };
    const [fieldValue, setFieldValue] = useState<ExpandableFieldValue | null>(
        fillFieldValue(),
    );
    const onLoad = (): string => {
        // check if global form state has the specified object and load it on UI
        if (
            form &&
            form[formId] &&
            form[formId]?.data[field?.origin.codeName]
        ) {
            // set value is a function provided from use form hook.
            return form[formId]?.data[field?.origin.codeName].value as string;
        }
        return "";
    };
    const [value, setValue] = useState(onLoad());

    const formattedGoogleAddress = (
        addressObject: AddressComponent[],
    ): FormattedAddressComponent => {
        const newAddressObject: FormattedAddressComponent = {};
        if (addressObject) {
            addressObject?.forEach((addressComponent) => {
                const keyName = addressComponent.types[0];
                newAddressObject[
                    keyName === "postal_code" ? "zipCode" : keyName
                ] = addressComponent;
            });
        }
        return newAddressObject;
    };

    //@ts-ignore
    const fillExpandableTab = (address) => {
        const selectedCity =
            (address?.["sublocality_level_1"] ||
                address?.["locality"] ||
                address?.["political"]) ??
            "";
        const selectedState = address?.["administrative_area_level_1"] ?? "";
        const selectedZipCode = address?.["zipCode"] ?? "";
        const displayValue = `${
            (selectedCity ? selectedCity.long_name : "") as string
        }, ${(selectedState ? selectedState.short_name : "") as string} ${
            (selectedZipCode ? selectedZipCode.short_name : "") as string
        }`;

        setFieldValue({
            displayValue: displayValue,
            value: selectedZipCode ? selectedZipCode.short_name : "",
        });
    };

    const validate = (val: string): boolean => {
        const regexExp = new RegExp(/^\d/);
        const doesMatch = regexExp.test(val.trimStart());
        if (!doesMatch) {
            const tempError = {
                [field.origin.codeName]: {
                    message: "Address should start with a number",
                    codeName: field.origin.codeName,
                },
            };
            dispatch({
                type: FormActionTypes.SET_ERRORS,
                payload: tempError,
            });
            setForm({
                codeName: field.origin.codeName,
                value: "",
                formId: formId,
                changed: true,
            });
            setFieldValue(null);
            return false;
        }
        return true;
    };

    const fillDataForHiddenFields = () => {
        const keys = Object.keys(expandableField?.[formId] ?? {});
        for (let i = 0; i < keys.length; i++) {
            if (
                expandableField?.[formId][keys[i]]?.ignoreField === "true" &&
                fieldValue
            )
                setForm({
                    codeName: expandableField?.[formId][keys[i]].contentType,
                    value: fieldValue?.value,
                    displayLabel:
                        fieldValue?.displayValue?.replace(
                            /(\r\n|\n|\r)/gm,
                            "",
                        ) ?? "",
                    formId: formId,
                    changed: true,
                });
        }
    };

    const validateAddressFromGoogle = async (value: string) => {
        if (value && value.length) {
            const addressRes: AddressInfoResponse = await getAddressInfo(value);
            const isValidAddress = validateAddress(addressRes);
            if (isValidAddress) {
                const address = formattedGoogleAddress(
                    addressRes.data.results[0]?.address_components,
                );
                fillExpandableTab(address);
            } else {
                setFieldValue(null);
                const tempError = {
                    [field.origin.codeName]: {
                        message: "Invalid Address",
                        codeName: field.origin.codeName,
                    },
                };
                dispatch({
                    type: FormActionTypes.SET_ERRORS,
                    payload: tempError,
                });
            }
        }
    };

    const validateAddress = (addressInfoResponse: AddressInfoResponse) => {
        if (addressInfoResponse.data.status !== "OK") {
            return false;
        }

        if (addressInfoResponse.data.status === "OK") {
            const streetNumber =
                addressInfoResponse.data.results[0]?.address_components.find(
                    (address) => address.types.includes("street_number"),
                )?.short_name;
            return streetNumber ? true : false;
        }
    };

    useEffect(() => {
        fillDataForHiddenFields();
    }, [fieldValue]);

    return (
        <div className="mb-4 w-full">
            <div
                className={
                    isFirstStep
                        ? "mb-3 font-bold text-center"
                        : questionAsTitle
                        ? `text-xl font-bold mb-[2rem] flex justify-center`
                        : `mb-3 flex`
                }
            >
                <label>
                    {field?.label ??
                        field.origin.label ??
                        field?.niceName ??
                        field.origin.niceName}
                </label>
            </div>
            <Autocomplete
                apiKey={process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY as string}
                id={field.origin.codeName}
                options={{
                    componentRestrictions: {
                        country: "us",
                    },
                    types: ["address"],
                    fields: [
                        "address_component",
                        "adr_address",
                        "alt_id",
                        "formatted_address",
                        "geometry",
                        "name",
                    ],
                }}
                //@ts-ignore
                value={value ?? ""}
                placeholder={
                    field?.placeholder ??
                    field.origin.placeholder ??
                    "Enter a value"
                }
                className={`rounded-lg  w-full text-base  px-5 ${
                    formErrors && formErrors[field?.origin.codeName]
                        ? styles["input-error"]
                        : styles["input"]
                }`}
                onPlaceSelected={(place) => {
                    const address = formattedGoogleAddress(
                        place.address_components as AddressComponent[],
                    );
                    setFieldChanged(true);
                    fillExpandableTab(address);
                    removeError(field.origin.codeName);
                    dispatch({
                        type: FormActionTypes.SET_ERRORS,
                        payload: {},
                    });
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    let val = place.formatted_address as string;
                    setValue(val.split(",")[0]); // to get only the street name and remove state and city
                    // validate if street address starts with a number
                    if (validate(val)) {
                        if (field.mask || field.origin.mask) {
                            val = handleFieldMasking(
                                field.mask ??
                                    (field.origin.mask as
                                        | "us-phone"
                                        | "zipCode"),
                                val,
                            );
                        }
                        // call set form ... check formStore.js to see implementation.
                        setForm({
                            codeName: field.origin.codeName,
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            value: val.split(",")[0],
                            formId: formId,
                            changed: true,
                        });
                    }
                }}
                onChange={(e) => {
                    removeError(field.origin.codeName);
                    dispatch({
                        type: FormActionTypes.SET_ERRORS,
                        payload: {},
                    });
                    let val = e.currentTarget.value;
                    setValue(val.split(",")[0]);
                    if (field.mask) {
                        val = handleFieldMasking(
                            field.mask ?? field.origin.mask,
                            val,
                        );
                    }
                    // if (validate(val)) {
                    setForm({
                        codeName: field.origin.codeName,
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        value: val.split(",")[0],
                        formId: formId,
                        changed: true,
                    });
                    // }
                }}
                onBlur={(e) => {
                    const val = e.currentTarget.value;
                    setTimeout(async () => {
                        const regex = new RegExp(/^[0-9].*/);
                        if (!regex.test(val))
                            await validateAddressFromGoogle(val);
                    }, 200);
                }}
            />
            {fieldValue && (
                <ExpandableTab
                    fieldValue={fieldValue}
                    setFieldValue={setFieldValue}
                    formId={formId}
                />
            )}
            {(field?.note || field.origin.note) && (
                <div className={`flex ${formStyles["note"]}`}>
                    {field?.note || field.origin.note}
                </div>
            )}
            {formErrors && formErrors[field.origin.codeName] && (
                <span
                    style={{ display: "flex", color: "red", fontSize: "12px" }}
                >
                    {formErrors?.[field.origin.codeName]?.message ??
                        "Field is required"}
                </span>
            )}
        </div>
    );
};

export default StreetAddress;
