import { gql } from "graphql-request";
export const OVERVIEW_GET_TEXT_BLOCKS_BY_POSITIONS = gql`
    query overviewGetTextBlocksByPosition(
        $id: Int
        $domainId: Int
        $positions: [String]
    ) {
        allCustomTextBlocksPositions(
            isActive: true
            positionIn: $positions
            isDeleted: false
        ) {
            textBlocks(
                categoryId: $id
                isDeleted: false
                isActive: true
                domainId: $domainId
            ) {
                position
                text
            }
        }
    }
`;
