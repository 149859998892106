/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getAccessToken } from "@services/cookies";
import { Field } from "@genericTypes/sharedTypes";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

type ValidationBody =
    | {
          ruleName: Field["validationType"];
          value: string;
      }
    | { ruleName: "fullName"; value: { firstName: string; lastName: string } };

export interface ValidationResponse {
    success: boolean;
    data: {
        value: string | { [x: string]: string };
        replaceValue: boolean;
    } | null;
}

export const validateField = async (
    data: ValidationBody,
): Promise<{
    data: ValidationResponse["data"] | null;
    error: Error | null;
}> => {
    const accessToken = getAccessToken();

    const { ruleName, value } = data;

    const config: AxiosRequestConfig = {
        method: "post",
        url: `/api/validation`,
        data: {
            rule: {
                name: ruleName,
                value,
                ...(ruleName === "email"
                    ? { domains: ["trafficbox.com"] }
                    : {}),
            },
            isSoft: true,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken as string}`,
        },
    };

    const { data: validationResponse, error } =
        await apiHandler<ValidationResponse>(() => HTTP.client(config));

    return {
        data: validationResponse?.data ?? null,
        error,
    };
};
