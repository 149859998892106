import React, { Dispatch, useEffect, useState } from "react";
import styles from "../../input/styles.module.scss";
import styles2 from "./styles.module.scss";
import { AllForms } from "@genericTypes/form-store-types";
import {
    ExpandableFieldMeta,
    Field,
    FormActionTypes,
} from "@genericTypes/sharedTypes";
import {
    addFieldAtom,
    formsAtom,
    removeErrorByCodeName,
} from "@store/formStore";
import { useAtom } from "jotai";
import {
    getZipCodeSuggestions,
    ZipCodeSearch,
} from "src/api/getZipCodeSuggestions";
import ExpandableTab from "../expandableTab";
import { handleFieldMasking } from "@helpers/formHelpers/form-masking";
import { useDispatch, useFormState } from "../../formReducer/FormReducer";

export interface SelectedField {
    [codeName: string]: string;
}

const ZipCodeExpandable = ({
    field,
    formId,
    setFieldChanged,
    questionAsTitle,
    isFirstStep,
}: {
    field: Field;
    formId: number;
    setFieldChanged: Dispatch<React.SetStateAction<boolean>>;
    questionAsTitle: boolean | undefined;
    isFirstStep: boolean;
}) => {
    function initExpandableFields() {
        let metaKeys: string[] = [];
        let isExpandableField: boolean = false;
        if (field?.meta && field.meta[0]) {
            metaKeys = field.meta ? Object.values(field.meta[0]) : [];
            // check if field has expandable meta key
            isExpandableField =
                Array.isArray(metaKeys) && metaKeys.length
                    ? metaKeys[0] === "expandable"
                        ? true
                        : false
                    : false;
        }
        if (isExpandableField) {
            const expandableFields = JSON.parse(
                metaKeys[1] ?? {},
            ) as ExpandableFieldMeta;
            return expandableFields;
        }
        return {};
    }
    const [form] = useAtom<AllForms>(formsAtom);
    const [, setForm] = useAtom(addFieldAtom);
    const [, removeError] = useAtom(removeErrorByCodeName);

    const [zipCode, setZipCode] = useState("");
    const [options, setOptions] = useState<ZipCodeSearch[]>([]);
    const [showOptions, setShowOptions] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [selectedZipCode, setSelectedZipCode] =
        useState<SelectedField | null>(null);
    const [currentExpandableFieldState] = useState<ExpandableFieldMeta>(
        initExpandableFields(),
    );

    const dispatch = useDispatch();
    const [formState] = useFormState();
    const { formErrors, modalVisibility } = formState;

    useEffect(() => {
        onLoad();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (zipCode.length > 2) {
                void fetchZipCodes(zipCode);
                return;
            }
            setOptions([]);
        }, 300);
    }, [zipCode]);

    const onLoad = () => {
        const keys = Object.keys(currentExpandableFieldState);
        let tempObj: SelectedField | null = null;
        for (let i = 0; i < keys.length; i++) {
            if (
                form &&
                form?.[formId] &&
                form?.[formId]?.data &&
                form?.[formId].data?.[keys[i]] &&
                form?.[formId].data?.[keys[i]].value
            ) {
                tempObj = {
                    //@ts-ignore
                    ...tempObj,
                    [keys[i]]: form?.[formId].data?.[keys[i]].value as string,
                };
            }
        }

        setZipCode(
            (form?.[formId]?.data?.[field?.origin.codeName]?.value as string) ??
                "",
        );
        setOptions([]);
        setSelectedZipCode(tempObj);
        setShowOptions(false);
    };

    const fillExpandableFields = (selectedZipCodeInfo: SelectedField) => {
        const keys = Object.keys(currentExpandableFieldState);
        for (let i = 0; i < keys.length; i++) {
            setForm({
                codeName: keys[i],
                formId: formId,
                value: selectedZipCodeInfo[keys[i]],
            });
        }
        setHasChanged(false);
    };

    const fetchZipCodes = async (zipCode: string) => {
        try {
            if (hasChanged) {
                const { data: result, error } = await getZipCodeSuggestions({
                    zipCode: zipCode,
                });
                if (error) return;
                if (result.length === 1) {
                    selectZipCode(result[0]);
                    setShowOptions(false);
                    setHasChanged(false);
                } else if (result.length > 1) {
                    setOptions(result);
                    setShowOptions(true);
                    setHasChanged(false);
                }
            }
        } catch (err) {
            setOptions([]);
            setShowOptions(false);
            setHasChanged(false);
        }
    };

    const change = (e: React.FormEvent<HTMLInputElement>) => {
        removeError(field.origin.codeName);
        dispatch({
            type: FormActionTypes.SET_ERRORS,
            payload: {},
        });
        setSelectedZipCode(null);
        setHasChanged(true);
        let value = e.currentTarget.value;
        if (field.mask) {
            value = handleFieldMasking(field.mask ?? field.origin.mask, value);
        }
        setZipCode(value);
        setForm({
            codeName: field.origin.codeName,
            value: value,
            formId: formId,
        });
    };

    const selectZipCode = (suggestion: ZipCodeSearch) => {
        setZipCode(suggestion.zipCode);
        setFieldChanged(true);
        const keys = Object.keys(currentExpandableFieldState);
        let tempObj: SelectedField = {};
        for (let i = 0; i < keys.length; i++) {
            const field = currentExpandableFieldState[keys[i]];
            tempObj = {
                ...tempObj,
                //@ts-ignore
                [keys[i]]: suggestion[field.content] as string,
            };
        }
        setSelectedZipCode(tempObj);
        fillExpandableFields(tempObj);
    };

    useEffect(() => {
        if (!modalVisibility) onLoad();
    }, [modalVisibility]);

    const renderOptions = () => {
        return (
            showOptions && (
                <div
                    id="suggestions-list"
                    className={`${
                        options.length
                            ? `z-10 overflow-y-auto text-left bg-[#fff] border-[1px] py-[10px] w-full  max-h-[110px] mb-[3px] absolute border-[#ccc] rounded`
                            : ""
                    } ${field.origin.codeName}-autocomplete`}
                >
                    {options.map((suggestion) => (
                        <div
                            key={`${suggestion.zipCode} - ${field.origin.codeName}`}
                            className={`${styles2["suggestion"]} py-[5px] px-[8px] hover:font-bold text-sm z-40`}
                            onClick={() => {
                                selectZipCode(suggestion);
                                setShowOptions(false);
                            }}
                        >
                            <span>{suggestion.zipCode}</span> -{" "}
                            <span>{suggestion.city}</span>,{" "}
                            <span>{suggestion.stateCode}</span>
                        </div>
                    ))}
                </div>
            )
        );
    };

    return (
        <div className="mb-4 z-1 w-full">
            <div
                className={
                    isFirstStep
                        ? "mb-3 font-bold text-center"
                        : questionAsTitle
                        ? `text-xl font-bold mb-[2rem] flex justify-center`
                        : `mb-3 flex`
                }
            >
                <label>
                    {field?.label ??
                        field.origin.label ??
                        field?.niceName ??
                        field.origin.niceName}
                </label>
            </div>
            <div className="w-full relative">
                <input
                    name={field.origin.codeName}
                    type={field?.fieldType ?? field.origin.fieldType}
                    onChange={(e) => change(e)}
                    onBlur={() =>
                        setTimeout(() => {
                            setShowOptions(false);
                        }, 300)
                    }
                    placeholder={
                        (field.placeholder as string) ??
                        field.origin.placeholder
                    }
                    className={`rounded-lg  w-full text-base  px-5 ${
                        formErrors && formErrors[field.origin.codeName]
                            ? styles["input-error"]
                            : styles["input"]
                    }`}
                    value={zipCode}
                    autoComplete={"off"}
                />
                {renderOptions()}
                {selectedZipCode &&
                    selectedZipCode?.[field.origin.codeName] && (
                        <ExpandableTab
                            withChangeBtn={false}
                            zipCodeExpandableValue={selectedZipCode}
                            currentExpandableFieldState={
                                currentExpandableFieldState
                            }
                        />
                    )}
            </div>
            {(field?.note || field.origin.note) && (
                <div
                    style={{
                        color: "lightGray",
                        marginTop: "5px",
                        display: "flex",
                    }}
                >
                    {field?.note ?? field.origin.note}
                </div>
            )}
            {formErrors && formErrors[field.origin.codeName] && (
                <span
                    style={{ color: "red", display: "flex", fontSize: "12px" }}
                >
                    {formErrors?.[field.origin.codeName]?.message ??
                        "Field is required"}
                </span>
            )}
        </div>
    );
};

export default ZipCodeExpandable;
