/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export const getUserMeta = () => {
    const ratio = window.devicePixelRatio || 1;
    const is_touch_device = "ontouchstart" in document.documentElement;
    const touchStatus = is_touch_device ? "touch" : "no-touch";
    const screenSize = `${screen.width}x${screen.height}`;
    const documentDimension = `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`;
    const rw = screen.width * ratio;
    const rh = screen.height * ratio;
    const widthHeightRatio = `${rw}x${rh}`;

    const connection =
        //@ts-ignore
        navigator?.connection ||
        //@ts-ignore
        navigator?.mozConnection ||
        //@ts-ignore
        navigator?.webkitConnection;

    const connectionEffectiveType: string =
        (connection?.effectiveType as string) ?? "";
    return {
        screenSize,
        documentDimension,
        ratio,
        widthHeightRatio,
        touchStatus,
        connection: connectionEffectiveType,
    };
};
