/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Field, ValidationErrors } from "@genericTypes/sharedTypes";
import { validateField } from "src/api/validation";

export const softValidation = async ({
    field,
    fieldValue,
}: {
    field: Field;
    fieldValue: string;
}): Promise<ValidationErrors> => {
    const validationType =
        field?.origin?.validationType || field?.validationType;
    if (
        validationType &&
        validationType !== "companyName" &&
        validationType !== "streetAddress" &&
        fieldValue !== ""
    ) {
        const { data, error } = await validateField({
            ruleName: validationType,
            value: fieldValue,
        });
        if (
            error &&
            //@ts-ignore
            error?.response &&
            //@ts-ignore
            (error?.response?.status === 504 ||
                //@ts-ignore
                error?.response?.status === 503 ||
                //@ts-ignore
                error?.response?.status === 502)
        ) {
            return {
                type: "valid",
                value: fieldValue,
                validationSkipped: true,
            };
        }
        if (error) {
            return {
                type: "error",
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                value: `${
                    //@ts-ignore
                    error?.response?.data?.validationMessage ??
                    //@ts-ignore
                    error?.response?.data?.message ??
                    `${
                        field.origin.niceName ?? field.origin.codeName
                    }  is invalid`
                }`,
                validationSkipped: false,
            };
        }

        if (data) {
            return {
                type: "valid",
                value: (data.value as string) ?? undefined,
                validationSkipped: false,
            };
        }
    }

    return {
        type: "",
        value: "",
        validationSkipped: false,
    };
};
