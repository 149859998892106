import { gql } from "graphql-request";
export const TEXT_BLOCKS_QUERY = gql`
    query textBlocks($offers: JSON) {
        allTextBlocks(offerIds: $offers, isDeleted: false) {
            text
            translations {
                key
                value
                language
            }
        }
    }
`;
