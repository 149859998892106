import {
    ClickOffer,
    FilterQuestion,
    ServerRequest,
} from "@genericTypes/sharedTypes";
import { getAccessToken, setListIdCookie } from "@services/cookies";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";
import { ServerResponse } from "http";
import router from "next/router";

export interface ClicksSearchList {
    listRequestId: string;
    result: ClickOffer[];
}

export interface ClicksSearchListResponse {
    success: boolean;
    data: ClicksSearchList | null;
}

const getQuestionsAsString = (questions: {
    [x: string]: Pick<FilterQuestion, "name" | "value">;
}) => {
    const initialValue = "";

    return Object.keys(questions).reduce(function (
        previousValue,
        currentValue,
        index,
    ) {
        const value = questions[currentValue].value;
        const name = questions[currentValue].name;

        if (!value) {
            return previousValue;
        }

        return (
            previousValue +
            `fields[${currentValue}]=${
                name === "birthDate" ? `${value}-01-01` : value
            }${index !== Object.keys(questions).length - 1 ? "&" : ""}`
        );
    },
    initialValue);
};

export const getClicksSearchList = async ({
    questions,
    categorySlug,
    crossSellingDomainFormId,
    req,
    res,
}: {
    questions: { [x: string]: Pick<FilterQuestion, "name" | "value"> };
    categorySlug: string;
    crossSellingDomainFormId?: string | null;
    res?: ServerResponse;
    req?: ServerRequest;
}): Promise<{ data: ClicksSearchList | null; error: Error | null }> => {
    const filters = getQuestionsAsString(questions);

    const accessToken = req ? req.accessToken : (getAccessToken() as string);

    const headers: { [x: string]: string } = {
        "X-DOMAIN-NAME": `${req ? req?.headers?.host ?? "" : ""}`,
        Authorization: `Bearer ${accessToken ?? ""}`,
    };

    if (!req) {
        headers["fields"] = filters;
    }

    const config: AxiosRequestConfig = {
        method: "get",
        headers,
        url: req
            ? `/api/web/v1/clicks/search?${filters}&categorySlug=${categorySlug}${
                  crossSellingDomainFormId
                      ? `&domainFormId=${crossSellingDomainFormId}`
                      : ""
              }${
                  //@ts-ignore
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  req.query.returnPrices
                      ? `&returnPrices=${
                            //@ts-ignore
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            req.query.returnPrices as string
                        }`
                      : ""
              }`
            : `/api/search?categorySlug=${categorySlug}&domainFormId=${
                  crossSellingDomainFormId as string
              }${
                  router.query.returnPrices
                      ? `&returnPrices=${router.query.returnPrices as string}`
                      : ""
              }`,
    };

    if (req) {
        const { data: clicksSearchListResponse, error } =
            await apiHandler<ClicksSearchListResponse>(() =>
                HTTP.server(config),
            );

        if (!error && clicksSearchListResponse) {
            setListIdCookie(
                clicksSearchListResponse.data?.listRequestId ?? null,
                req,
                res,
            );
        }

        return { data: clicksSearchListResponse?.data ?? null, error };
    } else {
        const { data: clicksSearchListResponse, error } =
            await apiHandler<ClicksSearchListResponse>(() =>
                HTTP.client(config),
            );

        if (!error && clicksSearchListResponse) {
            setListIdCookie(
                clicksSearchListResponse.data?.listRequestId ?? null,
                req,
                res,
            );
        }

        return { data: clicksSearchListResponse?.data ?? null, error };
    }
};
