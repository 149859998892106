import React, { ReactElement, useEffect, useState } from "react";
import { RenderFieldProps } from "@genericTypes/sharedTypes";
import { AllConditionalLogicFieldsAtom } from "@store/formStore";
import { useAtom } from "jotai";
import { getFieldType } from "../../../../helpers/formHelpers/get-field-type";
import Input from "@components/shared/form/input";
import ExpandableField from "@components/shared/form/expandableField";
import FormSelect from "@components/shared/form/formSelect";
import Radio from "@components/shared/form/radio";
import Checkbox from "@components/shared/form/checkbox";
import CustomDatePicker from "@components/shared/form/datePicker";
import Textarea from "../textarea";
import { useFormState } from "../formReducer/FormReducer";
import { FormErrors } from "@genericTypes/form-store-types";

const RenderField = ({
    fld,
    formId,
    stateErrors = null,
    errorsFromValidation = false,
    register,
    setValue,
    watch,
    inputAction,
    action = () => {},
    setFieldChanged,
    isSingleField,
    hasTitle,
    isFirstStep,
    formErrors,
    setError,
}: RenderFieldProps): ReactElement => {
    const [conditionalLogic] = useAtom(AllConditionalLogicFieldsAtom);
    const [formState] = useFormState();
    const { expandableField } = formState;
    const [inputErrors, setInputErrors] = useState<FormErrors | null>(
        formErrors,
    );

    useEffect(() => {
        setInputErrors(formErrors);
    }, [formErrors]);

    function checkIfFieldShouldShow(): boolean {
        if (conditionalLogic && conditionalLogic[formId]) {
            if (conditionalLogic[formId]?.[fld.origin.codeName]) {
                if (conditionalLogic[formId]?.[fld.origin.codeName].show) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    const getField = (): ReactElement => {
        const type = getFieldType(fld, formId, expandableField ?? {});
        const show = checkIfFieldShouldShow();
        switch (type) {
            case "text":
            case "email":
            case "number":
            case "tel":
                return show ? (
                    <Input
                        field={fld}
                        register={register}
                        key={fld.origin.codeName}
                        setValue={setValue}
                        stateErrors={stateErrors}
                        formId={formId}
                        errorsFromValidation={errorsFromValidation}
                        inputAction={inputAction}
                        setFieldChanged={setFieldChanged}
                        questionAsTitle={isSingleField && !hasTitle}
                        isFirstStep={isFirstStep}
                        formErrors={inputErrors}
                        setError={setError}
                    />
                ) : (
                    <></>
                );
            case "streetAddress":
            case "zipCodeExpandable":
                return show ? (
                    <ExpandableField
                        field={fld}
                        key={fld.origin.codeName}
                        formId={formId}
                        setFieldChanged={setFieldChanged}
                        questionAsTitle={isSingleField && !hasTitle}
                        isFirstStep={isFirstStep}
                    />
                ) : (
                    <></>
                );
            case "textarea":
                return show ? (
                    <Textarea
                        field={fld}
                        register={register}
                        key={fld.origin.codeName}
                        setValue={setValue}
                        stateErrors={stateErrors}
                        watch={watch}
                        formId={formId}
                        errorsFromValidation={errorsFromValidation}
                        questionAsTitle={isSingleField && !hasTitle}
                        isFirstStep={isFirstStep}
                    />
                ) : (
                    <></>
                );
            case "select":
            case "calculatedYears":
                return show ? (
                    <FormSelect
                        field={fld}
                        key={fld.origin.codeName}
                        placeholder={fld.placeholder ?? fld.niceName}
                        register={register}
                        watch={watch}
                        codeName={fld.origin.codeName}
                        formId={formId}
                        action={action}
                        setValue={setValue}
                        setFieldChanged={setFieldChanged}
                        isCalculatedYears={
                            fld.origin.fieldType === "calculatedYears"
                        }
                        questionAsTitle={isSingleField && !hasTitle}
                        isFirstStep={isFirstStep}
                    />
                ) : (
                    <></>
                );
            case "radio":
                return show ? (
                    <Radio
                        field={fld}
                        register={register}
                        required
                        key={fld.origin.codeName}
                        action={action}
                        formId={formId}
                        setFieldChanged={setFieldChanged}
                        questionAsTitle={!!(isSingleField && !hasTitle)}
                        isFirstStep={isFirstStep}
                    />
                ) : (
                    <></>
                );

            case "checkbox":
                return show ? (
                    <Checkbox
                        field={fld}
                        register={register}
                        key={fld.origin.codeName}
                        formId={formId}
                        setFieldChanged={setFieldChanged}
                        questionAsTitle={isSingleField && !hasTitle}
                        isFirstStep={isFirstStep}
                    />
                ) : (
                    <></>
                );

            case "date":
                return show ? (
                    <CustomDatePicker
                        field={fld}
                        key={fld.origin.codeName}
                        formId={formId}
                        setFieldChanged={setFieldChanged}
                        questionAsTitle={isSingleField && !hasTitle}
                        isFirstStep={isFirstStep}
                    />
                ) : (
                    <></>
                );
            default:
                return <></>;
        }

        return <></>;
    };

    return getField();
};

export default RenderField;
