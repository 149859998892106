import { gql } from "graphql-request";
export const POST_GET_CATEGORY_BY_ID = gql`
    query postGetCategoryById(
        $id: Int
        $language: String!
        $limit: [Int]
        $offset: [Int]
        $domainId: Int
        $published: Boolean
    ) {
        category(id: $id, isDeleted: false) {
            id
            name
            label
            slug
            translations {
                key
                value
                language
            }
            posts(
                limit: $limit
                offset: $offset
                categoryId: $id
                published: $published
                isDeleted: false
                orderByDesc: createdAt
                domainId: $domainId
            ) {
                title
                url {
                    isMain
                    main {
                        url
                    }
                    url
                }
            }
            url(isActive: true, isDeleted: false, languageEq: $language) {
                isMain
                main {
                    url
                }
                url
            }
        }
    }
`;
