import { gql } from "graphql-request";
export const TOTAL_ARTICLES = gql`
    query totalArticles(
        $id: Int
        $published: Boolean
        $domainId: Int
        $typeId: Int
    ) {
        category(id: $id) {
            posts(
                published: $published
                orderByDesc: createdAt
                domainId: $domainId
                isDeleted: false
                typeId: $typeId
            ) {
                id
                translations {
                    key
                    value
                    language
                }
            }
        }
    }
`;
