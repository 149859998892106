import { Field } from "@genericTypes/sharedTypes";
import { handleFieldMasking } from "@helpers/formHelpers/form-masking";
import { softValidation } from "@helpers/formHelpers/soft-validation-field-helper";
import {
    addFieldAtom,
    formErrorsAtom,
    formJsonAtom,
    removeErrorByCodeName,
} from "@store/formStore";
import { useAtom } from "jotai";
import React, { Dispatch, useEffect, useState } from "react";
import styles from "../../input/styles.module.scss";
import styles2 from "../../../modal/style.module.scss";

const EditPhoneNumber = ({
    setShowEditPhoneNumber,
    resetTimer,
}: {
    setShowEditPhoneNumber: Dispatch<React.SetStateAction<boolean>>;
    resetTimer: () => Promise<void>;
}) => {
    const [, setForm] = useAtom(addFieldAtom);
    const [errors, setErrors] = useAtom(formErrorsAtom);
    const [, removeError] = useAtom(removeErrorByCodeName);
    const [formJson] = useAtom(formJsonAtom);
    const [field, setField] = useState<Field>();
    const [newPhoneNumber, setNewPhoneNumber] = useState("");

    const change = (e: React.FormEvent<HTMLInputElement>): void => {
        // set field atom with data
        const { name } = e.currentTarget;
        removeError(name);
        setErrors({});
        let value = e.currentTarget.value;
        if (field?.mask) {
            value = handleFieldMasking(field?.mask, value);
        }
        setNewPhoneNumber(value);
    };

    const setNewPhoneNumberToForm = async () => {
        try {
            const validation = await softValidation({
                field: field as Field,
                fieldValue: newPhoneNumber,
            });
            if (validation && validation?.type === "error") {
                setErrors({
                    [field?.origin.codeName as string]: {
                        codeName: field?.origin.codeName as string,
                        message: validation.value as string,
                    },
                });
                return;
            }
            setForm({
                codeName: "phoneNumber",
                value: newPhoneNumber,
                formId: formJson?.id as number,
                changed: true,
            });
            setShowEditPhoneNumber(false);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log("error: ", error);
        }
    };

    const getFieldMask = () => {
        if (formJson) {
            for (let i = 0; i < formJson?.steps?.length; i++) {
                const step = formJson?.steps[i];
                for (let j = 0; j < step?.fields?.length; j++) {
                    const field = step?.fields[j];
                    if (
                        field?.origin.codeName === "phoneNumber" &&
                        (field.mask || field.origin.mask)
                    ) {
                        setField(field);
                        return;
                    }
                }
            }
        }
    };

    useEffect(() => {
        getFieldMask();
    }, []);

    return (
        <div className="w-full flex flex-col gap-5">
            <div className="w-full flex flex-col ">
                <h1 className="text-gray-400">Edit Your Phone Number</h1>
                <p className="text-lg tracking-wide font-extrabold">
                    Enter your phone number for a verification code
                </p>
            </div>
            <div className="flex flex-col gap-2">
                <input
                    className={`${styles["input"]} w-full rounded-[8px]`}
                    placeholder="New phone number"
                    value={newPhoneNumber}
                    onChange={change}
                />
                <button
                    onClick={async () => {
                        setShowEditPhoneNumber(false);
                        setNewPhoneNumber("");
                        removeError(field?.origin.codeName as string);
                        await resetTimer();
                    }}
                    className="text-[#1693F0] text-left w-fit underline"
                >
                    Cancel
                </button>
                {errors && errors[field?.origin.codeName as string] && (
                    <span style={{ color: "red" }}>
                        {errors?.[field?.origin.codeName as string]?.message ??
                            "Field is required"}
                    </span>
                )}
            </div>
            <div className="flex flex-row w-full gap-2 mt-6">
                <button
                    onClick={() => setNewPhoneNumberToForm()}
                    className={`${styles2["nextBtn"]}`}
                >
                    Edit
                </button>
            </div>
        </div>
    );
};

export default EditPhoneNumber;
