import { gql } from "graphql-request";
export const CATEGORY_OFFERS_QUERY = gql`
    query categoryOffersQuery(
        $categoryId: Int
        $language: String!
        $domainId: Int
    ) {
        allDomainOffers(domainId: $domainId, categoryId: $categoryId) {
            offers(orderByDesc: weight, isDeleted: false, isActive: true) {
                slug
                slugAlias
                url(isActive: true, isDeleted: false, languageEq: $language) {
                    isMain
                    main {
                        url
                    }
                    url
                }
                translations {
                    key
                    value
                    language
                }
                logo {
                    path
                    fullPath
                }
            }
        }
    }
`;
