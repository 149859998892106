import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

// eslint-disable-next-line react/display-name
export const ProgressBar = React.memo(
    ({
        total,
        current,
        className,
        color,
        shift = 0,
    }: {
        total: number;
        current: number;
        className: string;
        color: string | undefined;
        shift?: number;
    }): ReactElement => {
        const progress = (current + shift) / (total + 1 + shift - 1);

        const progressPercentage = Math.round(progress * 100);

        return (
            <div
                className={`${styles["progress-bar"]} ${className} ${
                    progressPercentage === 100 ? styles["success"] : ""
                }`}
            >
                <div
                    style={{
                        left: progressPercentage ? `${progressPercentage}%` : 0,
                        transform: "translateX(-50%)",
                        background: color,
                    }}
                    className={styles["percentage"]}
                >
                    <span className="text-xs">{progressPercentage}%</span>
                    <span
                        style={{ borderBottomColor: color }}
                        className={styles["arrow"]}
                    />
                </div>
                <div
                    className={`${styles["bar"]} relative h-6px w-full rounded-10 overflow-hidden`}
                >
                    <span
                        style={{
                            width: `${progressPercentage}%`,
                            background: color,
                        }}
                    />
                </div>
            </div>
        );
    },
    (prevProps, nextProps) => {
        return prevProps.current === nextProps.current;
    },
);
