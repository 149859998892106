import { AllForms } from "@genericTypes/form-store-types";
import { Field, FormActionTypes } from "@genericTypes/sharedTypes";
import {
    addFieldAtom,
    formsAtom,
    removeErrorByCodeName,
} from "@store/formStore";
import { useAtom } from "jotai";
import React, { Dispatch } from "react";
import DatePicker from "react-multi-date-picker";
import { useDispatch, useFormState } from "../formReducer/FormReducer";

export interface DateTimePickerProps {
    field: Field;
    formId: number;
    setFieldChanged: Dispatch<React.SetStateAction<boolean>>;
    questionAsTitle: boolean | undefined;
    isFirstStep: boolean;
}

const CustomDatePicker = ({
    field,
    formId,
    setFieldChanged,
    questionAsTitle,
    isFirstStep,
}: DateTimePickerProps) => {
    const [, setForm] = useAtom(addFieldAtom);
    const dispatch = useDispatch();
    const [formState] = useFormState();

    const [form] = useAtom<AllForms>(formsAtom);
    const { formErrors } = formState;
    const [, removeError] = useAtom(removeErrorByCodeName);

    const change = (date: string): void => {
        // call set form ... check formStore.js to see implementation.
        removeError(field.origin.codeName);
        dispatch({
            type: FormActionTypes.SET_ERRORS,
            payload: {},
        });
        setFieldChanged(true);
        if (date) {
            const currentDate = new Date(date);
            const minDate = getMinDate();
            const maxDate = getMaxDate();
            if (minDate !== undefined && minDate > currentDate) {
                const tempError = {
                    [field.origin.codeName]: {
                        codeName: field.origin.codeName,
                        message:
                            "please select a date within the required range",
                    },
                };
                dispatch({
                    type: FormActionTypes.SET_ERRORS,
                    payload: tempError,
                });
            } else if (maxDate !== undefined && maxDate < currentDate) {
                const tempError = {
                    [field.origin.codeName]: {
                        codeName: field.origin.codeName,
                        message:
                            "please select a date within the required range",
                    },
                };
                dispatch({
                    type: FormActionTypes.SET_ERRORS,
                    payload: tempError,
                });
            }
            setForm({
                codeName: field.origin.codeName,
                value: date || "",
                formId: formId,
                changed: true,
            });
            return;
        } else {
            const tempError = {
                [field.origin.codeName]: {
                    codeName: field.origin.codeName,
                    message: "please select a date within the required range",
                },
            };
            dispatch({
                type: FormActionTypes.SET_ERRORS,
                payload: tempError,
            });
            setForm({
                codeName: field.origin.codeName,
                value: date || "",
                formId: formId,
                changed: true,
            });
        }
    };

    const getMaxDate = () => {
        if (field.maxValue?.type === "number") {
            const today = new Date();
            today.setDate(
                today.getDate() + parseInt(field.maxValue.value as string),
            );
            return today;
        } else if (field.maxValue?.type === "date") {
            //@ts-ignore
            return new Date(field.maxValue?.value);
        } else {
            return undefined;
        }
    };

    const getMinDate = () => {
        if (field.minValue?.type === "number") {
            const today = new Date();
            today.setDate(
                today.getDate() +
                    parseInt(
                        (field.minValue.value as string) ??
                            field.origin.minValue?.value,
                    ),
            );
            return today;
        } else if (field.minValue?.type === "date") {
            //@ts-ignore
            return new Date(
                (field.minValue?.value as Date) ?? field.origin.minValue?.value,
            );
        } else {
            return undefined;
        }
    };

    return (
        <div className="w-full">
            <div
                className={
                    isFirstStep
                        ? "mb-3 font-bold text-center"
                        : questionAsTitle
                        ? `text-xl font-bold mb-[2rem] flex justify-center`
                        : `mb-3 flex`
                }
            >
                <label>
                    {field?.label ??
                        field.origin.label ??
                        field?.niceName ??
                        field.origin.niceName}
                </label>
            </div>
            <DatePicker
                value={
                    (form[formId].data[field?.origin.codeName]
                        ?.value as string) ?? ""
                }
                placeholder={
                    field.placeholder
                        ? field.placeholder
                        : field.origin.placeholder
                        ? field.origin.placeholder
                        : (field.label as string)
                        ? (field.label as string)
                        : field.origin.label
                }
                onChange={(date) => {
                    change(date?.toString() as string);
                }}
                inputClass={`w-full border rounded-[8px] p-2 text-center cursor-pointer ${
                    formErrors && formErrors[field.origin.codeName]
                        ? "border border-[red]"
                        : ""
                }`}
                containerClassName={`w-[100%]`}
                calendarPosition="bottom-center"
                maxDate={getMaxDate()}
                format={"MM/DD/YYYY"}
                minDate={getMinDate()}
                onOpenPickNewDate={false}
            />
            {(field?.note || field.origin.note) && (
                <div
                    style={{
                        color: "gray",
                        width: "fit-content",
                        height: "fit-content",
                        fontSize: "12px",
                    }}
                >
                    {field?.note || field.origin.note}
                </div>
            )}
            {formErrors && formErrors[field.origin.codeName] && (
                <span
                    style={{ display: "flex", color: "red", fontSize: "12px" }}
                >
                    {formErrors?.[field.origin.codeName]?.message ??
                        "Field is required"}
                </span>
            )}
        </div>
    );
};

export default CustomDatePicker;
