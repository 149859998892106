import {
    AllExpandableFieldsMeta,
    ExpandableFieldMeta,
    Field,
    FieldTypes,
} from "@genericTypes/sharedTypes";

export const getFieldType = (
    field: Field,
    formId: number,
    expandableFieldsAtom?: AllExpandableFieldsMeta,
): FieldTypes => {
    if (
        expandableFieldsAtom &&
        expandableFieldsAtom[formId] &&
        expandableFieldsAtom[formId][field.origin.codeName] &&
        expandableFieldsAtom[formId][field.origin.codeName].ignoreField ===
            "true"
    )
        return "";
    let metaKeys: string[] = [];
    let isExpandableField: boolean = false;
    if (field?.meta && field.meta[0]) {
        metaKeys = field.meta ? Object.values(field.meta[0]) : [];
        // check if field has expandable meta key
        isExpandableField =
            Array.isArray(metaKeys) && metaKeys.length
                ? metaKeys[0] === "expandable"
                    ? true
                    : false
                : false;
    } else if (field?.origin.meta && field.origin.meta[0]) {
        metaKeys = field.origin.meta ? Object.values(field.origin.meta[0]) : [];
        // check if field has expandable meta key
        isExpandableField =
            Array.isArray(metaKeys) && metaKeys.length
                ? metaKeys[0] === "expandable"
                    ? true
                    : false
                : false;
    }
    if (isExpandableField) {
        const expandableFields = JSON.parse(
            metaKeys[1] ?? {},
        ) as ExpandableFieldMeta;
        const keys = Object.keys(expandableFields);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === field.origin.codeName) {
                field.fieldType = expandableFields[keys[i]]
                    ?.contentType as FieldTypes;
                return expandableFields[keys[i]].contentType as FieldTypes;
            }
        }
        return field.origin.fieldType;
    }
    return field.origin.fieldType;
};
