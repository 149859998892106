import React, { ReactElement, ReactNode } from "react";
import styles from "./styles.module.scss";
import { Slide } from "react-awesome-reveal";
import { translate } from "@helpers/translationObjectFormatter";
import { FormActionTypes, TranslationsObject } from "@genericTypes/sharedTypes";
import { useQuery } from "@tanstack/react-query";
import {
    useDispatch,
    useFormState,
} from "@components/shared/form/formReducer/FormReducer";

interface Props {
    className?: string;
    children: ReactNode;
    onModalClose?: () => void;
}

export default function FormModal({
    children,
    className,
    onModalClose,
}: Props): ReactElement {
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    const dispatch = useDispatch();
    const [formState] = useFormState();
    const { showThankYou } = formState;

    const showModalHandler = () => {
        dispatch({
            type: FormActionTypes.SET_MODAL_VISIBILITY,
            payload: false,
        });
        if (onModalClose !== undefined) onModalClose();
    };

    return (
        <section
            className={`flex items-center justify-center ${
                showThankYou ? "flex-col gap-6" : "flex-col sm:flex-row-reverse"
            } ${styles["modalStyle"]}`}
        >
            <div
                // cascade
                // duration={400}
                className={`${
                    styles["contentStyle"]
                } bg-white sm:max-w-[750px] relative bottom-[2%] sm:bottom-0 rounded-[8px] w-[92%] sm:w-[45%] h-fit sm:h-[70%] overflow-auto py-5 overflow-x-hidden flex justify-center ${
                    className ?? ""
                }`}
            >
                <>{children}</>
            </div>
            <p
                className={`${
                    showThankYou ? "block" : "relative sm:hidden"
                } p-0 text-[#ffffff64] text-[16px] font-normal underline cursor-pointer w-full text-center `}
                onClick={showModalHandler}
            >
                {translate(
                    translations,
                    "several_general_formModalBackPreviousPage",
                )}
            </p>
            {!showThankYou && (
                <Slide className="hidden sm:flex flex-col justify-evenly h-full mt-[230px]">
                    <>
                        <h2 className={`${styles["modalFormText"]} pe-8`}>
                            {translate(
                                translations,
                                "several_general_formModalText",
                            )}
                            <span
                                style={{
                                    color: "#F9BB21",
                                    marginLeft: "1rem",
                                    padding: "0",
                                }}
                            >
                                →
                            </span>
                        </h2>
                        <p
                            className="text-[#ffffff64] text-[16px] font-normal underline cursor-pointer w-fit "
                            onClick={showModalHandler}
                        >
                            {translate(
                                translations,
                                "several_general_formModalBackPreviousPage",
                            )}
                        </p>
                    </>
                </Slide>
            )}
        </section>
    );
}
