import { gql } from "graphql-request";
export const TAGS_OPTIONS = gql`
    query tagsOptions($domainId: Int, $type: String, $typeId: Int) {
        allTags(isDeleted: false, type: $type) {
            id
            name
            slug
            posts(
                typeId: $typeId
                published: true
                isDeleted: false
                domainId: $domainId
                orderByDesc: createdAt
            ) {
                id
                translations {
                    key
                    value
                    language
                }
            }
        }
    }
`;
