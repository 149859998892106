import React, { ReactElement } from "react";
import { GQLCategories, GQLOffers } from "@gql/types/gql";
import TopBuyerItem from "@components/category/topBuyerItem";
import { Fade } from "react-awesome-reveal";

export default function Widget({
    type,
    thankYouOffers,
    selectedCategory,
}: {
    type: string;
    thankYouOffers: GQLOffers[];
    selectedCategory: GQLCategories;
}): ReactElement {
    switch (type) {
        case "offers":
            return (
                <div className="max-w-[500px] sm:max-w-[750px] gap-2 flex flex-row justify-center sm:flex-row flex-wrap my-5">
                    {thankYouOffers.map(
                        (offer) =>
                            offer && (
                                <Fade key={offer.id ?? offer.description}>
                                    <TopBuyerItem
                                        vendor={offer}
                                        categoryId={selectedCategory?.id ?? -1}
                                        featured={false}
                                        hideReview={true}
                                        imageClassName={
                                            "!w-[90px] sm:!w-[100px] !h-[90px] sm!h-[100px]"
                                        }
                                        containerStyle={
                                            "w-[141px] sm:w-[180px] h-[150px] sm:h-[190px]"
                                        }
                                    />
                                </Fade>
                            ),
                    )}
                </div>
            );

        default:
            return <></>;
    }
}
