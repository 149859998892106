import { gql } from "graphql-request";
export const CATEGORY_POSTS_BY_CATEGORY_ID = gql`
    query categoryPostsByCategoryId(
        $id: Int
        $limit: [Int]
        $offset: [Int]
        $published: Boolean
        $domainId: Int
        $language: String
        $typeId: Int
    ) {
        category(id: $id) {
            posts(
                limit: $limit
                offset: $offset
                published: $published
                orderByDesc: createdAt
                domainId: $domainId
                isDeleted: false
                typeId: $typeId
            ) {
                id
                title
                image {
                    fullPath
                    path
                }
                translations {
                    key
                    value
                    language
                }
                url(isActive: true, isDeleted: false, languageEq: $language) {
                    isMain
                    main {
                        url
                    }
                    url
                }
            }
        }
    }
`;
