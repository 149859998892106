import { gql } from "graphql-request";
export const TOTAL_ALL_WRITERS_POSTS = gql`
    query totalAllWritersPosts($domainId: Int, $userId: String) {
        allPosts(
            published: true
            isDeleted: false
            domainId: $domainId
            orderByDesc: createdAt
            userId: $userId
        ) {
            translations {
                key
                value
                language
            }
        }
    }
`;
