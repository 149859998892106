import { gql } from "graphql-request";
export const HOME_PAGE_TOO_VENDORS_QUERY = gql`
    query getTopVendors($language: String!, $domainId: Int) {
        allDomainOffers(domainId: $domainId) {
            categoryId
            offers(orderByDesc: weight, isDeleted: false, isActive: true) {
                slug
                logo {
                    path
                    fullPath
                }
                url(isActive: true, isDeleted: false, languageEq: $language) {
                    main {
                        url
                    }
                    isMain
                    url
                }
            }
        }
    }
`;
