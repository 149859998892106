import { Field } from "@genericTypes/sharedTypes";
import React, { Dispatch, ReactElement } from "react";
import StreetAddress from "./streetAddress";
import ZipCodeExpandable from "./zipCodeExpandable";

export interface FieldTypeLookup {
    [codeName: string]: ReactElement;
}

const ExpandableField = ({
    field,
    formId,
    setFieldChanged,
    questionAsTitle,
    isFirstStep,
}: {
    field: Field;
    formId: number;
    setFieldChanged: Dispatch<React.SetStateAction<boolean>>;
    questionAsTitle: boolean | undefined;
    isFirstStep: boolean;
}) => {
    const getFieldType: FieldTypeLookup = {
        streetAddress: (
            <StreetAddress
                field={field}
                formId={formId}
                key={field.origin.codeName}
                setFieldChanged={setFieldChanged}
                questionAsTitle={questionAsTitle}
                isFirstStep={isFirstStep}
            />
        ),
        zipCodeExpandable: (
            <ZipCodeExpandable
                field={field}
                formId={formId}
                key={field.origin.codeName}
                setFieldChanged={setFieldChanged}
                questionAsTitle={questionAsTitle}
                isFirstStep={isFirstStep}
            />
        ),
    };

    return <>{getFieldType[field.fieldType as string]}</>;
};

export default ExpandableField;
