import { gql } from "graphql-request";
export const GET_TEXT_BLOCKS_BY_POSITIONS = gql`
    query getTextBlocksByPosition(
        $id: Int
        $domainId: Int
        $positions: [String]
    ) {
        allCustomTextBlocksPositions(isActive: true, positionIn: $positions) {
            textBlocks(
                categoryId: $id
                isDeleted: false
                isActive: true
                domainId: $domainId
            ) {
                position
                text
            }
        }
    }
`;
