import { gql } from "graphql-request";
export const CATEGORY_BY_SLUG_PAGINATION = gql`
    query categoryBySlugPagination(
        $slug: String
        $offset: [Int]
        $limit: [Int]
        $domainId: Int
        $language: String
    ) {
        category(slug: $slug) {
            id
            name
            title
            slug
            languages
            translations {
                key
                value
                language
            }
            url(isActive: true, isDeleted: false, languageEq: $language) {
                isMain
                main {
                    url
                }
                url
            }
            posts(
                limit: $limit
                offset: $offset
                published: true
                orderByDesc: createdAt
                isDeleted: false
                domainId: $domainId
                typeId: 2295
            ) {
                id
                domainId
                title
                translations {
                    key
                    value
                    language
                }
                image {
                    fullPath
                }
                url(isActive: true, isDeleted: false, languageEq: $language) {
                    isMain
                    main {
                        url
                    }
                    url
                }
            }
        }
    }
`;
