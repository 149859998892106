import React, { memo, ReactElement, useEffect } from "react";
import { useAtom } from "jotai";
import styles from "../input/styles.module.scss";
import {
    formsAtom,
    addFieldAtom,
    removeErrorByCodeName,
} from "@store/formStore";
import {
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from "react-hook-form";
import { Field, FormActionTypes } from "@genericTypes/sharedTypes";
import { AllForms, FormErrors } from "@genericTypes/form-store-types";
import { useDispatch, useFormState } from "../formReducer/FormReducer";

interface FieldValues {
    [key: string]: string[] | string | number;
}
const Textarea = ({
    field,
    register,
    setValue,
    formId,
    stateErrors,
    questionAsTitle,
    isFirstStep,
}: {
    field: Field;
    register: UseFormRegister<{}>;
    setValue: UseFormSetValue<{}>;
    formId: number;
    watch: UseFormWatch<FieldValues>;
    stateErrors: FormErrors | null;
    errorsFromValidation?: boolean;
    questionAsTitle: boolean | undefined;
    isFirstStep: boolean;
}): ReactElement => {
    const [form] = useAtom<AllForms>(formsAtom);
    const [, setForm] = useAtom(addFieldAtom);
    const [, removeError] = useAtom(removeErrorByCodeName);

    const dispatch = useDispatch();
    const [formState] = useFormState();
    const { formErrors } = formState;

    const change = (e: React.FormEvent<HTMLInputElement>): void => {
        // set field atom with data
        const { name, value } = e.currentTarget;
        removeError(name);
        dispatch({
            type: FormActionTypes.SET_ERRORS,
            payload: {},
        });
        setValue(field.origin.codeName as never, value as never, {
            shouldDirty: true,
            shouldTouch: true,
        });
        // call set form ... check formStore.js to see implementation.
        setForm({
            codeName: name,
            value: value,
            formId: formId,
            changed: true,
        });
    };

    const onLoad = () => {
        // check if global form state has the specified object and load it on UI
        if (form && form[formId] && form[formId]?.data[field.origin.codeName]) {
            // set value is a function provided from use form hook.
            setValue(
                field.origin.codeName as never,
                form[formId].data[field.origin.codeName].value as never,
                {
                    shouldDirty: true,
                    shouldTouch: true,
                },
            );
        } else {
            setValue(field.origin.codeName as never, "" as never, {
                shouldDirty: false,
                shouldTouch: false,
            });
        }
    };

    useEffect(() => {
        // this is used when the form has errors after submission
        dispatch({
            type: FormActionTypes.SET_ERRORS,
            payload: stateErrors ?? {},
        });
        onLoad();
    }, []);

    return (
        <div className="mb-5 w-full">
            <div
                className={
                    isFirstStep
                        ? "mb-3 font-bold text-center"
                        : questionAsTitle
                        ? `text-xl font-bold mb-[2rem] flex justify-center`
                        : `mb-3 flex`
                }
            >
                <label>
                    {field?.label ??
                        field.origin.label ??
                        field?.niceName ??
                        field.origin.niceName}
                </label>
            </div>
            <textarea
                {...register(field?.origin.codeName as never, {
                    onChange: change,
                })}
                placeholder={
                    (field.placeholder as string) ?? field.origin.placeholder
                }
                className={`rounded-lg  w-full text-base  px-5 ${
                    formErrors && formErrors[field.origin.codeName]
                        ? styles["input-error"]
                        : styles["input"]
                }`}
                rows={4}
            />
            {(field?.note || field.origin.note) && (
                <div className="flex">{field?.note || field.origin.note}</div>
            )}
            {formErrors && formErrors[field.origin.codeName] && (
                <span
                    style={{ display: "flex", color: "red", fontSize: "12px" }}
                >
                    {formErrors?.[field.origin.codeName]?.message ??
                        "Field is required"}
                </span>
            )}
        </div>
    );
};

function areEqual(
    prevState: Readonly<{
        field: Field;
        register: UseFormRegister<{}>;
        setValue: UseFormSetValue<{}>;
        formId: number;
        watch: UseFormWatch<FieldValues>;
        stateErrors: FormErrors | null;
        errorsFromValidation: boolean;
        questionAsTitle: boolean | undefined;
        isFirstStep: boolean;
    }>,
    nextState: Readonly<{
        field: Field;
        register: UseFormRegister<{}>;
        setValue: UseFormSetValue<{}>;
        formId: number;
        watch: UseFormWatch<FieldValues>;
        stateErrors: FormErrors | null;
        errorsFromValidation: boolean;
        questionAsTitle: boolean | undefined;
        isFirstStep: boolean;
    }>,
): boolean {
    return (
        prevState?.field?.origin.codeName === nextState?.field?.origin.codeName
    );
}

export default memo(Textarea, areEqual);
