import React, { ReactElement, useEffect, useState } from "react";
import styles from "../style.module.scss";
import { Field, StepProps } from "../../../../../src/genericTypes/sharedTypes";
import Spinner from "../../spinner";
import styles2 from "../../modal/formModal/styles.module.scss";
import RenderField from "@components/shared/form/renderField/render-field";
import { useAtom } from "jotai";
import { AllConditionalLogicFieldsAtom, formsAtom } from "@store/formStore";
import { titleDecoder } from "@lib/sharedUtils";
import { Session } from "src/api/session";
import { useQuery } from "@tanstack/react-query";
import animation from "./styles.module.scss";

export default function Step({
    step,
    register,
    stepNumber,
    currentStep,
    action,
    setValue,
    watch,
    formId,
    buttonLoading,
    inputAction,
    isSingleField,
    formErrors,
    setError,
}: StepProps): ReactElement {
    const [AllConditionalLogicFields] = useAtom(AllConditionalLogicFieldsAtom);
    const [fieldChanged, setFieldChanged] = useState(false);
    const [form] = useAtom(formsAtom);
    const session = useQuery<Session>({ queryKey: ["session"] });

    function renderButton() {
        if (
            step.fields.length === 1 &&
            (step.fields[0].fieldType === "radio" ||
                step.fields[0].origin.fieldType === "radio")
        ) {
            return <></>;
        }
        return (
            <div className="w-full">
                <button
                    className={`${styles["nextBtn"]} ${styles2["nextBtn"]} h-[3rem] font-bold`}
                    type="submit"
                    value={step.buttonText ?? "Submit"}
                    disabled={buttonLoading}
                    id="submitBtn"
                >
                    {buttonLoading ? (
                        <Spinner size={25} color="white" success={false} />
                    ) : (
                        step.buttonText ?? "Submit"
                    )}
                </button>
            </div>
        );
    }

    function checkIfFieldShouldBeVisible(field: Field): boolean {
        if (
            AllConditionalLogicFields &&
            AllConditionalLogicFields[formId] &&
            AllConditionalLogicFields[formId]?.[field.origin.codeName]
        ) {
            return AllConditionalLogicFields[formId][field.origin.codeName]
                ?.show as boolean;
        } else {
            return true;
        }
    }

    useEffect(() => {
        if (fieldChanged) {
            setFieldChanged(false);
        }
    }, [fieldChanged, form]);

    const animationEnded = () => {
        if (currentStep === stepNumber) {
            const codeName = step.fields[0].origin.codeName;
            const fieldType = step.fields[0].origin.fieldType;
            if (
                fieldType === "email" ||
                fieldType === "tel" ||
                fieldType === "text" ||
                fieldType === "textarea" ||
                fieldType === "streetAddress" ||
                fieldType === "zipCodeExpandable"
            ) {
                const element = document.getElementById(codeName);
                element?.focus();
            }
        }
    };

    if (currentStep === stepNumber) {
        return (
            // <Slide
            //     direction="right"
            //     duration={500}
            //     delay={10}
            //     damping={0.5}
            //     triggerOnce
            // >
            <div
                className={`w-full flex flex-col text-center ${animation["slide-left"]}`}
                onAnimationEnd={animationEnded}
            >
                {step.title && (
                    <div
                        className={`w-full text-lg ${
                            currentStep === 0 ? "sm:text-3xl" : "sm:text-2xl"
                        } mb-5 font-bold text-center`}
                    >
                        {titleDecoder(step.title, {
                            region:
                                (session.data?.visitDetails
                                    ?.region as string) ?? "",
                        })}
                    </div>
                )}
                {step.description && (
                    <div className="text-semibold sm:text-lg flex justify-center text-center mb-5">
                        {step.description}
                    </div>
                )}

                {step.fields?.map((fld) =>
                    checkIfFieldShouldBeVisible(fld) || fieldChanged ? (
                        <RenderField
                            fld={fld}
                            formId={formId}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            inputAction={inputAction}
                            action={action}
                            key={fld.origin.codeName}
                            setFieldChanged={setFieldChanged}
                            isSingleField={isSingleField as boolean}
                            hasTitle={!!step.title}
                            isFirstStep={currentStep === 0}
                            formErrors={formErrors}
                            setError={setError}
                        />
                    ) : (
                        <></>
                    ),
                )}
                {renderButton()}
            </div>
            // </Slide>
        );
    }

    return <></>;
}
