import { gql } from "graphql-request";
export const GET_OFFERS_BY_CATEGORY_ID = gql`
    query getOffersByCategoryId(
        $categoryId: Int
        $domainId: Int
        $language: String!
    ) {
        allDomainOffers(
            categoryId: $categoryId
            domainId: $domainId
            orderByDesc: weight
        ) {
            placements
            weight
            offers(isDeleted: false, isActive: true, bodyIsNull: false) {
                title
                mainImage {
                    id
                    path
                    fullPath
                }
                logo {
                    id
                    path
                    fullPath
                }
                subTitle
                summaryDescription
                summaryTitle
                name
                slug
                url(isActive: true, isDeleted: false, languageEq: $language) {
                    isMain
                    main {
                        url
                    }
                    url
                }
                defaultAffiliateLink {
                    link
                }
                translations {
                    key
                    value
                    language
                }
                features(languageEq: $language) {
                    id
                    type
                    description
                    translations {
                        key
                        value
                        language
                    }
                }
                attributes(isDeleted: false) {
                    value
                    id
                    translations {
                        key
                        value
                        language
                    }
                    baseAttribute(
                        hidden: false
                        forceComparisonsDisplay: false
                        comparison: false
                    ) {
                        weight
                        comparison
                        name
                        hidden
                        group
                        groupSlug
                        forceComparisonsDisplay
                        translations {
                            key
                            value
                            language
                        }
                    }
                }
                pros(languageEq: $language) {
                    id
                    type
                    description
                    translations {
                        key
                        value
                        language
                    }
                }
                cons(languageEq: $language) {
                    id
                    type
                    description
                    translations {
                        key
                        value
                        language
                    }
                }
            }
        }
    }
`;
