import { gql } from "graphql-request";

export const ALL_CATEGORIES = gql`
    query allCategories($domainId: Int) {
        domain(id: $domainId) {
            categories(
                limit: 100
                orderBy: createdAt
                isPublished: true
                isDeleted: false
            ) {
                id
                parentId
                title
                name
                slug
                isPublished
                label
                url(isActive: true, isDeleted: false) {
                    isMain
                    main {
                        url
                    }
                    url
                }
                translations {
                    key
                    value
                    language
                }
                image {
                    path
                    fullPath
                }
                categoryChilds {
                    id
                    slug
                    parentId
                }
                mainImage {
                    fullPath
                    path
                }
                icon {
                    id
                    fullPath
                    path
                }
                offers(
                    isActive: true
                    isDeleted: false
                    bodyIsNull: false
                    orderByDesc: weight
                ) {
                    id
                    title
                    name
                    slug
                    url(isActive: true, isDeleted: false) {
                        isMain
                        main {
                            url
                        }
                        url
                    }
                    defaultAffiliateLink {
                        link
                    }
                    translations {
                        key
                        value
                        language
                    }
                    logo {
                        fullPath
                    }
                }
            }
        }
    }
`;
