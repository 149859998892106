import { gql } from "graphql-request";
export const TOTAL_NEWS_BY_TAGS = gql`
    query totalNewsByTags($slug: String, $domainId: Int) {
        tag(slug: $slug, isDeleted: false) {
            id
            name
            posts(
                typeId: 2296
                published: true
                isDeleted: false
                domainId: $domainId
                orderByDesc: id
            ) {
                id
                translations {
                    key
                    value
                    language
                }
            }
        }
    }
`;
