import { gql } from "graphql-request";
export const CATEGORY_WITH_FORMS = gql`
    query allDomainForms($domainId: Int) {
        allDomainForms(domainId: $domainId, isDeleted: false, isActive: true) {
            id
            domainId
            categoryId
            category {
                id
                label
                name
                slug
                TCPAMessage
            }
        }
    }
`;
