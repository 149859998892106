import React, {
    Dispatch,
    memo,
    ReactElement,
    useEffect,
    useState,
} from "react";
import { Field, FormActionTypes, ItemOption } from "@genericTypes/sharedTypes";
import styles from "./styles.module.scss";
import { UseFormRegister } from "react-hook-form";
import {
    formsAtom,
    addFieldAtom,
    removeErrorByCodeName,
} from "@store/formStore";
import { useAtom } from "jotai";
import { AllForms } from "@genericTypes/form-store-types";
import { useDispatch, useFormState } from "../formReducer/FormReducer";

const Checkbox = ({
    field,
    register,
    formId,
    setFieldChanged,
    questionAsTitle,
    isFirstStep,
}: {
    field: Field;
    register: UseFormRegister<{}>;
    formId: number;
    setFieldChanged: Dispatch<React.SetStateAction<boolean>>;
    questionAsTitle: boolean | undefined;
    isFirstStep: boolean;
}): ReactElement => {
    const [form] = useAtom<AllForms>(formsAtom);

    const dispatch = useDispatch();
    const [formState] = useFormState();
    const { formErrors } = formState;

    const [, setForm] = useAtom(addFieldAtom);
    const [selectedValues, setSelectedValues] = useState<string[]>(onLoad);
    const [, removeError] = useAtom(removeErrorByCodeName);
    const [options] = useState<ItemOption[]>(
        (field.defaultValues?.options as ItemOption[]) ??
            (field.origin.defaultValues?.options as ItemOption[]),
    );

    const change = (e: React.FormEvent<HTMLInputElement>) => {
        removeError(e.currentTarget.name);
        dispatch({
            type: FormActionTypes.SET_ERRORS,
            payload: {},
        });
        setFieldChanged(true);
        const { value } = e.currentTarget;
        if (!selectedValues.includes(value)) {
            setSelectedValues([...selectedValues, value]);
        }
        if (selectedValues.includes(value)) {
            setSelectedValues(selectedValues.filter((item) => item !== value));
        }
    };

    function onLoad(): string[] {
        // check if global form state has the specified object and load it on UI
        return !!form &&
            form[formId] &&
            form[formId].data[field.origin.codeName]
            ? (form[formId].data[field.origin.codeName]?.value as string).split(
                  ",",
              )
            : [];
    }

    useEffect(() => {
        setForm({
            codeName: field.origin.codeName,
            value: selectedValues.filter((item) => item !== "").join(","),
            formId: formId,
            valid: selectedValues.length > 0,
        });
    }, [selectedValues]);

    return (
        <div className={`${styles["field"]} ${styles["checkbox"]} `}>
            <div
                className={
                    isFirstStep
                        ? "mb-3 font-bold text-center"
                        : questionAsTitle
                        ? `text-xl font-bold mb-[2rem] flex justify-center`
                        : `mb-3 flex`
                }
            >
                <label>
                    {field?.label ??
                        field.origin.label ??
                        field?.niceName ??
                        field.origin.niceName}
                </label>
            </div>
            {options?.map((option, index) => (
                <div
                    className={`${styles["option"]} ${
                        selectedValues.includes(option.value as string)
                            ? "bg-[#eff5ff]"
                            : ""
                    }`}
                    key={index}
                >
                    <input
                        {...register(field.origin.codeName as never, {
                            onChange: change,
                        })}
                        type="checkbox"
                        id={`${field.origin.codeName}-${index}`}
                        value={option.value}
                        checked={selectedValues.includes(
                            option.value as string,
                        )}
                    />
                    <label
                        key={index}
                        htmlFor={`${field.origin.codeName}-${index}`}
                    >
                        <span className={styles["box"]}>
                            <svg viewBox="0,0,50,50">
                                <path d="M5 30 L 20 45 L 45 5"></path>
                            </svg>
                        </span>
                        {option?.icon && (
                            <span className="flex items-center mr-2">
                                <img
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                    src={option?.icon?.fullPath}
                                    alt={option.label as string}
                                    className="h-8 w-auto max-w-[50px]"
                                />
                            </span>
                        )}
                        <span
                            className="text-sm flex items-center"
                            style={{ transform: "translateY(1px)" }}
                        >
                            {option.label}
                        </span>
                    </label>
                </div>
            ))}
            <div className="flex flex-col gap-2 w-full">
                {(field?.note || field.origin.note) && (
                    <div className={`${styles["note"]}`}>
                        {field?.note || field.origin.note}
                    </div>
                )}
                {formErrors && formErrors[field.origin.codeName] && (
                    <span
                        style={{
                            display: "flex",
                            color: "red",
                            fontSize: "12px",
                        }}
                    >
                        {formErrors?.[field.origin.codeName]?.message ||
                            "Field is required"}
                    </span>
                )}
            </div>
        </div>
    );
};

function areEqual(
    prevState: Readonly<{
        field: Field;
        register: UseFormRegister<{}>;
        formId: number;
        setFieldChanged: Dispatch<React.SetStateAction<boolean>>;
        questionAsTitle: boolean | undefined;
        isFirstStep: boolean;
    }>,
    nextState: Readonly<{
        field: Field;
        register: UseFormRegister<{}>;
        formId: number;
        setFieldChanged: Dispatch<React.SetStateAction<boolean>>;
        questionAsTitle: boolean | undefined;
        isFirstStep: boolean;
    }>,
): boolean {
    return (
        prevState?.field?.origin.codeName === nextState?.field?.origin.codeName
    );
}

export default memo(Checkbox, areEqual);
