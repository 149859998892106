import { gql } from "graphql-request";

export const ALL_CATEGORIES_CATEGORY_SLUG = gql`
    query allCategoriesCategorySlug($domainId: Int) {
        domain(id: $domainId) {
            categories(isPublished: true, isDeleted: false) {
                id
                parentId
                title
                name
                slug
                label
                translations {
                    key
                    value
                    language
                }
                posts(
                    published: true
                    orderByDesc: createdAt
                    isDeleted: false
                ) {
                    id
                    title
                    translations {
                        key
                        value
                        language
                    }
                    image {
                        fullPath
                        path
                    }
                    url {
                        url
                    }
                }
            }
        }
    }
`;
