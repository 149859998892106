import { gql } from "graphql-request";

export const ALL_VARIATIONS = gql`
    query allVariations($type: String, $orderByDesc: VariationsPropertiesEnum) {
        allVariations(
            type: $type
            orderByDesc: $orderByDesc
            isDeleted: false
        ) {
            id
            label
            value
        }
    }
`;
