import { gql } from "graphql-request";
export const OVERVIEW_DOMAIN_OFFER_QUERY = gql`
    query getOverviewDomainOffers(
        $categoryId: Int
        $language: String!
        $domainId: Int
        $slugs: [String!]
    ) {
        allDomainOffers(domainId: $domainId, categoryId: $categoryId) {
            offers(
                orderByDesc: weight
                isDeleted: false
                isActive: true
                slugIn: $slugs
            ) {
                id
                languages
                weight
                categories {
                    id
                    name
                    title
                }
                title
                subTitle
                quickOverview
                featuresListTitle
                description
                displayUrl
                name
                weight
                slug
                slugAlias
                url(isActive: true, isDeleted: false, languageEq: $language) {
                    isMain
                    main {
                        url
                    }
                    url
                }
                domains {
                    id
                }
                defaultAffiliateLink {
                    link
                }
                translations {
                    key
                    value
                    language
                }
                faqs(languageEq: $language, isDeleted: false) {
                    id
                    question
                    answer
                }
                workingHours {
                    id
                    isActive
                    timeZone
                    schedule
                }
                attributes(isDeleted: false) {
                    value
                    id
                    translations {
                        key
                        value
                        language
                    }
                    baseAttribute(categoryId: $categoryId) {
                        weight
                        comparison
                        name
                        hidden
                        group
                        categoryId
                        groupSlug
                        forceComparisonsDisplay
                        type
                        attributesGroup {
                            id
                            slug
                            order
                            name
                            isActive
                        }
                        translations {
                            key
                            value
                            language
                        }
                    }
                }
                mainImage {
                    path
                    fullPath
                }
                defaultAffiliateLinkId
                summaryTitle
                summaryDescription
                image {
                    path
                    fullPath
                }
                features(languageEq: $language) {
                    id
                    type
                    description
                    translations {
                        key
                        value
                        language
                    }
                }
                pros(languageEq: $language) {
                    id
                    type
                    description
                    translations {
                        key
                        value
                        language
                    }
                }
                cons(languageEq: $language) {
                    id
                    type
                    description
                    translations {
                        key
                        value
                        language
                    }
                }
                logo {
                    path
                    fullPath
                }
            }
        }
    }
`;
