import { gql } from "graphql-request";
export const LOOKUPS_BY_IDS = gql`
    query lookupsByIds($ids: [Int], $language: String!) {
        allLookups(idIn: $ids) {
            label
            id
            translations(languageEq: $language) {
                key
                value
                language
            }
        }
    }
`;
