import { gql } from "graphql-request";
export const USER_BY_SLUG = gql`
    query userBySlug($nicknameSlug: String) {
        user(nicknameSlug: $nicknameSlug) {
            id
            name
            nickname
            nicknameSlug
            position
            bio
        }
    }
`;
