import { gql } from "graphql-request";
export const ALL_DKIS = gql`
    query allDkis {
        allDkis(isActive: true) {
            id
            type
            isActive
            name
        }
    }
`;
