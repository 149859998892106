import React, { ReactElement } from "react";

export default function StarIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="517"
            height="493"
            fill="white"
            viewBox="0 0 517 493"
        >
            <path
                fillRule="evenodd"
                d="M257.542.014a11.2 11.2 0 00-10.105 7.719l-56.223 168.68H11.164a11.2 11.2 0 00-6.613 20.21l146.25 106.94-56.223 174.56v.004a11.197 11.197 0 004.086 12.535 11.203 11.203 0 0013.184-.039l146.25-106.94 146.25 106.94a11.203 11.203 0 0013.184.039 11.196 11.196 0 004.086-12.535l-56.223-174.56 146.25-106.94v-.004a11.2 11.2 0 00-6.613-20.21h-180.05L268.759 7.733a11.2 11.2 0 00-11.207-7.72h-.01z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}
