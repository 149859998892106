import { gql } from "graphql-request";
export const CROSS_SELLING_DOMAIN_FORM = gql`
    query crossSellingDomainForm($id: Int, $categoryId: Int) {
        domain(id: $id) {
            domainCategories(categoryId: $categoryId) {
                crossSellingDomainFormId
            }
        }
    }
`;
