import { gql } from "graphql-request";
export const RECENT_POSTS_BY_CATEGORY = gql`
    query recentPostsByCategory(
        $limit: [Int]
        $offset: [Int]
        $domainId: Int
        $published: Boolean
        $categoryId: Int
    ) {
        allPosts(
            limit: $limit
            offset: $offset
            categoryId: $categoryId
            published: $published
            isDeleted: false
            orderByDesc: createdAt
            domainId: $domainId
        ) {
            id
            domainId
            categoryId
            title
            translations {
                key
                value
                language
            }
            url {
                id
                modelId
                title
                language
                isActive
                url
                isMain
                main {
                    url
                }
            }
        }
    }
`;
