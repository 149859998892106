import { gql } from "graphql-request";
export const NEWS_BY_TAGS = gql`
    query newsByTagsQuery($domainId: Int) {
        allPosts(
            limit: 200
            offset: 6
            published: true
            isDeleted: false
            typeId: 2296
            orderByDesc: createdAt
            domainId: $domainId
        ) {
            title
            createdAt
            image {
                fullPath
                path
            }
            url {
                isActive
                url
                isMain
                main {
                    url
                }
            }
            tags {
                name
            }
        }
    }
`;
