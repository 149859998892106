import React from "react";
import { translate } from "@helpers/translationObjectFormatter";
import { TranslationsObject } from "@genericTypes/sharedTypes";
import { useQuery } from "@tanstack/react-query";
import styles from "./style.module.scss";
function FormMainLabel() {
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    return (
        <span className={` ${styles["badge"]}  `}>
            {translate(translations, "several_general_formLabelTopOfTheForm")}
        </span>
    );
}

export default FormMainLabel;
