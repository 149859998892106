import { gql } from "graphql-request";

export const SITEMAP_GET_ALL_DOMAIN_CATEGORIES = gql`
    query sitemapGetAllDomainCategories($domainId: Int) {
        allDomainCategories(domainId: $domainId) {
            category(
                isPublished: true
                isDeleted: false
                parentIdIsNull: false
            ) {
                id
                parentId
                title
                label
                name
                translations {
                    key
                    value
                    language
                }
                url(isActive: true, isDeleted: false) {
                    isMain
                    main {
                        url
                    }
                    url
                }
            }
        }
    }
`;
