import { gql } from "graphql-request";

export const ALL_CATEGORIES_SLUGS = gql`
    query allCategoriesSlugs($domainId: Int) {
        domain(id: $domainId) {
            categories(
                limit: 100
                orderBy: createdAt
                isPublished: true
                isDeleted: false
                parentIdIsNull: false
            ) {
                id
                parentId
                title
                name
                slug
                isPublished
                label
                translations {
                    key
                    value
                    language
                }
            }
        }
    }
`;
