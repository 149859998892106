import {
    AllConditionalLogicFieldsByForm,
    AllForms,
    FormErrors,
    Regulations,
} from "@genericTypes/form-store-types";
import { Field } from "@genericTypes/sharedTypes";
import { fieldValidator } from "./field-validator";
import { softValidation } from "./soft-validation-field-helper";

export async function stepValidator(
    steps: Field[],
    forms: AllForms,
    conditionalLogic: AllConditionalLogicFieldsByForm,
    fieldRegulation: Regulations | null,
    formId: number,
): Promise<FormErrors | null> {
    let errors: FormErrors = {};
    for (let i = 0; i < steps?.length; i++) {
        let validationsSkipped = false;
        if (
            shouldValidate(steps[i]?.origin?.fieldType) ||
            !forms?.[formId]?.data?.[steps[i]?.origin?.codeName]?.value ||
            forms?.[formId]?.data?.[steps[i]?.origin?.codeName]?.value === ""
        ) {
            const softValidationRes = await softValidation({
                field: steps[i],
                fieldValue:
                    (forms?.[formId]?.data?.[steps[i]?.origin?.codeName]
                        ?.value as string) ?? "",
            });
            if (softValidationRes.validationSkipped) {
                errors = {
                    ...errors,
                    [steps[i].origin.codeName]: {
                        codeName: steps[i].origin.codeName,
                        message: "VALIDATION_SKIPPED",
                    },
                };
                validationsSkipped = true;
            }
            if (
                fieldRegulation &&
                //@ts-ignore
                softValidationRes.value?.stateCode ===
                    fieldRegulation.stateRegulation &&
                !validationsSkipped
            ) {
                errors = {
                    ...errors,
                    [steps[i].origin.codeName]: {
                        codeName: steps[i].origin.codeName,
                        message: "California zip codes aren't allowed!",
                        type: "regulations",
                    },
                };
            }
            if (softValidationRes.type === "error" && !validationsSkipped) {
                errors = {
                    ...errors,
                    [steps[i].origin.codeName]: {
                        codeName: steps[i].origin.codeName,
                        message: softValidationRes.value as string,
                    },
                };
            }
            errors = {
                ...errors,
                ...fieldValidator(
                    steps[i],
                    forms,
                    errors,
                    conditionalLogic,
                    formId,
                ),
            };
        }
    }
    return errors;
}

function shouldValidate(type: string) {
    return !(type === "radio" || type === "checkbox");
}
