const wordToBeRemoved = ["vpn", "antivirus", "pos", "crm"];

export const removeWordFromString = (str: string): string => {
    wordToBeRemoved.forEach((word) => {
        if (str?.includes(`-${word}`)) {
            const split = str.split("-");
            if (split.length === 2) {
                str = split[0];
            } else {
                split.pop();
                str = split.join("-");
            }
        }
    });
    return str;
};
