import {
    AllConditionalLogicFieldsByForm,
    AllForms,
    FormErrors,
    IField,
} from "@genericTypes/form-store-types";
import { Field } from "@genericTypes/sharedTypes";
import { getFromStorage } from "@helpers/storageHelper";

export function fieldValidator(
    field: Field,
    forms: AllForms,
    errors: FormErrors,
    conditionalLogic: AllConditionalLogicFieldsByForm,
    formId: number,
): FormErrors {
    const formFromStorage = getFromStorage("local", "form");
    let form = null;
    if (formFromStorage) form = JSON.parse(formFromStorage) as AllForms;
    const currentFieldValues = form?.[formId]?.data?.[field?.origin?.codeName];
    if (!currentFieldValues) {
        errors = {
            ...errors,
            [field?.origin?.codeName]: {
                codeName: field.origin?.codeName,
                message:
                    field?.errorMessage ??
                    field?.origin?.errorMessage ??
                    "Field is required",
            },
        };
        return errors;
    }
    if (
        field?.isRequired &&
        shouldValidateIfConditional(
            field,
            formId,
            conditionalLogic,
            currentFieldValues,
        )
    ) {
        const fieldType = field?.fieldType || field.origin?.fieldType;
        if (
            fieldType === "text" ||
            fieldType === "email" ||
            fieldType === "number" ||
            fieldType === "tel" ||
            fieldType === "zipCodeExpandable"
        ) {
            if (
                !currentFieldValues ||
                !currentFieldValues?.value ||
                currentFieldValues.value === ""
            ) {
                errors = {
                    ...errors,
                    [field?.origin?.codeName]: {
                        codeName: field.origin?.codeName,
                        message:
                            field.errorMessage ??
                            field.origin.errorMessage ??
                            "Field is required",
                    },
                };
                return errors;
            }
            if (field?.pattern || field.origin.pattern) {
                const regexExp = new RegExp(
                    field?.pattern ?? field.origin?.pattern,
                );
                const matchesPattern = regexExp.test(
                    (currentFieldValues?.value as string) ?? "",
                );
                if (!matchesPattern) {
                    return (errors = {
                        ...errors,
                        [field.origin.codeName]: {
                            codeName: field.origin.codeName,
                            message: field.errorMessage || "Field is required",
                        },
                    });
                }
            }
        } else if (fieldType === "checkbox") {
            if (
                !currentFieldValues ||
                currentFieldValues.value === "" ||
                //@ts-ignore
                currentFieldValues.value.length === 0
            )
                return (errors = {
                    ...errors,
                    [field.origin.codeName]: {
                        codeName: field.origin.codeName,
                        message: field.errorMessage || "Field is required",
                    },
                });
        } else if (fieldType === "select" || fieldType === "calculatedYears") {
            if (
                !currentFieldValues ||
                !currentFieldValues?.value ||
                currentFieldValues?.value === "-1"
            ) {
                return (errors = {
                    ...errors,
                    [field.origin.codeName]: {
                        codeName: field.origin.codeName,
                        message: field.errorMessage || "Field is required",
                    },
                });
            }
        } else if (fieldType === "date") {
            if (!currentFieldValues || !currentFieldValues.value) {
                errors = {
                    ...errors,
                    [field.origin.codeName]: {
                        codeName: field.origin.codeName,
                        message: field.errorMessage || "Field is required",
                    },
                };
            } else {
                const currentDate = new Date(
                    currentFieldValues.value as string,
                );
                const minDate = getMinDate(field);
                const maxDate = getMaxDate(field);
                if (minDate !== undefined && minDate > currentDate) {
                    errors = {
                        ...errors,
                        [field.origin.codeName]: {
                            codeName: field.origin.codeName,
                            message:
                                "please select a date within the required range",
                        },
                    };
                }
                if (maxDate !== undefined && maxDate < currentDate) {
                    errors = {
                        ...errors,
                        [field.origin.codeName]: {
                            codeName: field.origin.codeName,
                            message:
                                "please select a date within the required range",
                        },
                    };
                }
            }
        } else if (fieldType === "textarea") {
            if (!currentFieldValues || !currentFieldValues?.value) {
                return (errors = {
                    ...errors,
                    [field.origin.codeName]: {
                        codeName: field.origin.codeName,
                        message: field.errorMessage || "Field is required",
                    },
                });
            }
        } else if (fieldType === "streetAddress") {
            if (
                !currentFieldValues ||
                !currentFieldValues?.value ||
                currentFieldValues.value === ""
            ) {
                return (errors = {
                    ...errors,
                    [field.origin.codeName]: {
                        codeName: field.origin.codeName,
                        message: field.errorMessage || "Field is required",
                    },
                });
            }
            if (
                !doesStreetAddressStartWithNumber(
                    currentFieldValues.value as string,
                )
            ) {
                return (errors = {
                    ...errors,
                    [field.origin.codeName]: {
                        codeName: field.origin.codeName,
                        message: "Street address should start with a number",
                    },
                });
            }
        }
    }
    return errors;
}
const getMaxDate = (field: Field) => {
    if (
        field.maxValue?.type === "number" ||
        field.origin.maxValue?.type === "number"
    ) {
        const today = new Date();
        today.setDate(
            today.getDate() +
                parseInt(
                    (field?.maxValue?.value as string) ||
                        (field.origin.maxValue?.value as string),
                ),
        );
        return today;
    } else if (
        field.maxValue?.type === "date" ||
        field.origin.maxValue?.type === "date"
    ) {
        //@ts-ignore
        return new Date(field.maxValue?.value || field.origin.maxValue?.value);
    } else {
        return undefined;
    }
};

const getMinDate = (field: Field) => {
    if (
        field.minValue?.type === "number" ||
        field.origin.minValue?.type === "number"
    ) {
        const today = new Date();
        today.setDate(
            today.getDate() +
                parseInt(
                    (field?.minValue?.value as string) ??
                        (field.origin.minValue?.value as string),
                ),
        );
        return today;
    } else if (
        field.minValue?.type === "date" ||
        field.origin.minValue?.type === "date"
    ) {
        //@ts-ignore
        return new Date(field.minValue?.value || field.origin.minValue?.value);
    } else {
        return undefined;
    }
};

const doesStreetAddressStartWithNumber = (val: string): boolean => {
    const regexExp = new RegExp(/^\d/);
    return regexExp.test(val.trimStart());
};

function shouldValidateIfConditional(
    field: Field,
    formId: number,
    conditionalLogic: AllConditionalLogicFieldsByForm,
    currentFieldValues: IField,
): boolean {
    if (conditionalLogic && conditionalLogic?.[formId]) {
        if (conditionalLogic[formId][field.origin.codeName]) {
            if (
                conditionalLogic[formId][field.origin.codeName].show &&
                conditionalLogic[formId][field.origin.codeName]
                    .requiredWhenShown
            ) {
                return true;
            } else if (
                !conditionalLogic[formId][field.origin.codeName].show &&
                currentFieldValues?.value === ""
            ) {
                return false;
            }
        } else {
            return true;
        }
    }
    return true;
}
