import { AllForms } from "@genericTypes/form-store-types";

export const decodeFieldsFromText = (
    text: string,
    form: AllForms,
    formId: number,
): string => {
    let clonedText = text;

    const dynamicStrings = text?.match(/\${([^}]*)}/g);
    dynamicStrings?.forEach((item) => {
        const codeName = item.replace("${", "").replace("}", "");
        if (form[formId]?.data[codeName]?.value) {
            const temp = clonedText.replace(
                item,
                form[formId].data[codeName].displayLabel ??
                    (form[formId].data[codeName].value as string),
            );
            clonedText = temp;
        }
    });

    return clonedText;
};
