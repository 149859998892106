import { gql } from "graphql-request";
export const TAG_BY_SLUG = gql`
    query tagBySlug(
        $slug: String
        $limit: [Int]
        $offset: [Int]
        $domainId: Int
        $typeId: Int
    ) {
        tag(slug: $slug, isDeleted: false) {
            id
            name
            posts(
                limit: $limit
                offset: $offset
                typeId: $typeId
                published: true
                isDeleted: false
                domainId: $domainId
                orderByDesc: createdAt
            ) {
                id
                title
                translations {
                    key
                    value
                    language
                }
                image {
                    fullPath
                }
                tags {
                    name
                }
                url {
                    id
                    title
                    language
                    isActive
                    url
                    isMain
                    main {
                        url
                    }
                }
            }
        }
    }
`;
