import { gql } from "graphql-request";
export const CATEGORIES_PAGE_DOMAIN_OFFERS_QUERY = gql`
    query getCategoriesPageDomainOffers($language: String!, $domainId: Int) {
        allDomainOffers(domainId: $domainId, orderByDesc: weight) {
            categoryId
            offers(isDeleted: false, isActive: true) {
                name
                summaryTitle
                title
                url(isActive: true, isDeleted: false, languageEq: $language) {
                    main {
                        url
                    }
                    isMain
                    url
                }
            }
        }
    }
`;
