import { gql } from "graphql-request";
export const ALL_POSTS_SITE_MAP = gql`
    query allPostsSiteMap($domainId: Int, $language: String) {
        allPosts(
            published: true
            isDeleted: false
            orderByDesc: createdAt
            domainId: $domainId
            categoryIdIsNull: false
        ) {
            id
            title
            translations {
                key
                value
                language
            }
            url(language: $language, isMain: true) {
                language
                url
            }
        }
    }
`;
