import { gql } from "graphql-request";
export const TOP_3_BUYERS_QUERY = gql`
    query getTop3Buyers($categoryId: Int, $language: String!, $domainId: Int) {
        allDomainOffers(
            domainId: $domainId
            categoryId: $categoryId
            orderByDesc: weight
            limit: 3
        ) {
            offers(isDeleted: false, isActive: true) {
                languages
                name
                weight
                slug
                url(isActive: true, isDeleted: false, languageEq: $language) {
                    isMain
                    main {
                        url
                    }
                    url
                }
                defaultAffiliateLink {
                    link
                }
                translations {
                    key
                    value
                    language
                }
                workingHours {
                    id
                    isActive
                    timeZone
                    schedule
                }
                logo {
                    path
                    fullPath
                }
            }
        }
    }
`;
