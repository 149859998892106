import React, { Dispatch, useState } from "react";
import { ExpandableFieldValue } from "@genericTypes/sharedTypes";
import styles from "../../input/styles.module.scss";
import Spinner from "@components/shared/spinner";
import { validateField } from "src/api/validation";
import { useAtom } from "jotai";
import {
    addFieldAtom,
    formErrorsAtom,
    removeErrorByCodeName,
} from "@store/formStore";
import { SelectedField } from "../zipCodeExpandable";
import { ExpandableFieldMeta } from "@genericTypes/sharedTypes";

const ExpandableTab = ({
    fieldValue,
    setFieldValue,
    zipCodeExpandableValue,
    formId,
    withChangeBtn = true,
    currentExpandableFieldState,
}: {
    fieldValue?: ExpandableFieldValue | null;
    zipCodeExpandableValue?: SelectedField | null;
    setFieldValue?: Dispatch<React.SetStateAction<ExpandableFieldValue | null>>;
    formId?: number;
    withChangeBtn?: boolean;
    currentExpandableFieldState?: ExpandableFieldMeta;
}) => {
    const [showChangeInfoTab, setShowChangeInfoTab] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [updatedZipCode, setUpdatedZipCode] = useState(
        fieldValue?.value ?? "",
    );
    const [errors, setErrors] = useAtom(formErrorsAtom);
    const [, setForm] = useAtom(addFieldAtom);
    const [, removeError] = useAtom(removeErrorByCodeName);

    const zipCodeChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
        removeError("zipCode");
        setUpdatedZipCode(e.currentTarget.value);
    };

    const updateBtnHandler = async (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        e.preventDefault();
        removeError("zipCode");
        setButtonLoading(true);
        const { data, error } = await validateField({
            ruleName: "zipCode",
            value: updatedZipCode,
        });
        if (error && !data) {
            setErrors({
                zipCode: {
                    codeName: "zipCode",
                    //@ts-ignore
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    message: error.response?.data?.message as string,
                },
            });
            setButtonLoading(false);
            return;
        }
        if (data && !error && updatedZipCode && updatedZipCode.length) {
            setFieldValue!({
                //@ts-ignore
                displayValue: `${data?.value?.city as string}, ${
                    //@ts-ignore
                    data?.value?.stateCode as string
                } ${updatedZipCode}`,
                value: updatedZipCode,
            });
            setForm({
                codeName: "zipCode",
                value: updatedZipCode,
                formId: formId as number,
                changed: true,
            });
        }
        setShowChangeInfoTab(false);
        setButtonLoading(false);
    };

    const getAddress = (): string => {
        if (fieldValue) return fieldValue.displayValue;

        if (currentExpandableFieldState && zipCodeExpandableValue) {
            const strBuilder = [];
            const keys = Object.keys(currentExpandableFieldState);
            for (let i = 0; i < keys.length; i++) {
                strBuilder.push(` ${zipCodeExpandableValue[keys[i]]}`);
            }
            return strBuilder.join();
        }
        return "";
    };
    return (
        <div className="mt-1 w-[100%]">
            {!showChangeInfoTab ? (
                <div className="bg-[#ffecbc64] border-[1px] text-sm border-[#ffdc82] flex justify-between w-fit px-4 py-1 rounded">
                    <div>
                        {getAddress()}
                        {fieldValue ? <span>&nbsp;&nbsp;</span> : <></>}
                    </div>
                    {withChangeBtn && (
                        <span
                            className="text-[#27AAE1] underline cursor-pointer"
                            onClick={() => setShowChangeInfoTab(true)}
                        >
                            Change
                        </span>
                    )}
                </div>
            ) : (
                <div
                    className="w-full mt-2 rounded bg-[#f8f8f8]"
                    style={{ boxShadow: "0 4px 12px rgba(0,0,0,.1)" }}
                >
                    <div className="font-semibold bg-[#f2f2f2] px-4 py-2 rounded">
                        {fieldValue?.displayValue}
                    </div>
                    <div className="flex flex-col gap-1 px-4 py-4">
                        <label>Zip Code</label>
                        <input
                            value={updatedZipCode}
                            className={`rounded-lg  w-full text-base  px-5 min-h-[40px] ${styles["input"]}`}
                            onChange={(e) => zipCodeChangeHandler(e)}
                        />
                        {errors && errors["zipCode"] && (
                            <>
                                <span
                                    style={{
                                        display: "flex",
                                        color: "red",
                                        fontSize: "12px",
                                    }}
                                >
                                    {errors?.["zipCode"]?.message ??
                                        "Field is required"}
                                </span>
                                <span
                                    style={{ color: "gray", fontSize: "11px" }}
                                >
                                    * if u enter an invalid zipcode the default
                                    will be selected
                                </span>
                            </>
                        )}
                    </div>
                    <div className="flex gap-4 mx-4 pb-5">
                        <div
                            className="w-[100%] bg-white text-[#107fd4] border border-[#107fd4] rounded text-center cursor-pointer py-1.5"
                            onClick={(e) => updateBtnHandler(e)}
                        >
                            <button
                                className={`${styles["nextBtn"]}`}
                                //@ts-ignore
                                onClick={(e) => updateBtnHandler(e)}
                            >
                                {buttonLoading ? (
                                    <Spinner
                                        size={25}
                                        color="white"
                                        success={false}
                                    />
                                ) : (
                                    "Update"
                                )}
                            </button>
                        </div>
                        <div
                            className="w-[100%] bg-white border rounded text-center cursor-pointer py-1.5"
                            onClick={() => {
                                setUpdatedZipCode(fieldValue?.value as string);
                                removeError("zipCode");
                                setShowChangeInfoTab(false);
                            }}
                        >
                            <button
                                className={`${styles["nextBtn"]}`}
                                type="submit"
                                id="submitBtn"
                            >
                                {buttonLoading ? (
                                    <Spinner
                                        size={25}
                                        color="white"
                                        success={false}
                                    />
                                ) : (
                                    "Cancel"
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExpandableTab;
