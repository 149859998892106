import { gql } from "graphql-request";
export const ALL_POSTS = gql`
    query allPosts(
        $limit: [Int]
        $offset: [Int]
        $domainId: Int
        $published: Boolean
    ) {
        allPosts(
            limit: $limit
            offset: $offset

            published: $published
            isDeleted: false
            orderByDesc: createdAt
            domainId: $domainId
        ) {
            id
            domainId
            categoryId
            title
            imageId
            featured
            published
            translations {
                key
                value
                language
            }
            image {
                fullPath
                path
            }
            url {
                id
                modelId
                title
                language
                isActive
                url
            }
        }
    }
`;
