import { SharedFields } from "@helpers/jotaiHelpers/form-store-helpers";
import { getFromStorage } from "@helpers/storageHelper";

const SHARED_FIELDS_CODENAME = [
    "firstName",
    "lastName",
    "phoneNumber",
    "email",
];
export function getSharedValueFromLocalStorage(codeName: string): string {
    const value = "";
    if (SHARED_FIELDS_CODENAME.includes(codeName)) {
        const loadedData = getFromStorage("local", "sharedFields");
        if (loadedData && loadedData.length) {
            const parsedLoadedData: SharedFields = JSON.parse(
                loadedData,
            ) as SharedFields;
            if (parsedLoadedData[codeName]) return parsedLoadedData[codeName];
        }
    }
    return value;
}
