import { gql } from "graphql-request";
export const POST_BY_ID = gql`
    query PostById($id: Int) {
        post(id: $id) {
            id
            excerpt
            domainId
            categoryId
            title
            published
            featured
            related {
                title
                url {
                    url
                }
            }
            translations {
                key
                value
                language
            }
            imageId
            image {
                id
                path
                fullPath
                caption
            }
            tags {
                id
                name
                slug
                isPublished
            }
            url {
                id
                modelId
                model
                language
                isActive
                url
            }
            metatags {
                key
                value
            }
            body
        }
    }
`;
