import { gql } from "graphql-request";
export const FEATURED_POSTS_HOME_PAGE = gql`
    query featuredPostsHomePage($domainId: Int) {
        allPosts(
            limit: 6
            offset: 0
            featured: true
            published: true
            isDeleted: false
            typeId: 2295
            orderByDesc: createdAt
            domainId: $domainId
        ) {
            id
            title
            translations {
                key
                value
                language
            }
            image {
                fullPath
            }
            url {
                id
                modelId
                title
                language
                isActive
                url
                isMain
                main {
                    url
                }
            }
        }
    }
`;
