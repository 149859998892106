import { gql } from "graphql-request";
export const GET_CATEGORY_BY_ID = gql`
    query getCategoryById($id: Int, $language: String!) {
        category(id: $id, isDeleted: false) {
            id
            name
            label
            title
            TCPAMessage
            isPublished
            languages
            slug
            slugAlias
            url(isActive: true, isDeleted: false, languageEq: $language) {
                isMain
                main {
                    url
                }
                url
            }
            attributes(hidden: false, orderByDesc: weight, isDeleted: false) {
                type
                weight
                value
                id
                comparison
                name
                hidden
                group
                groupSlug
                forceComparisonsDisplay
                attributesGroup {
                    id
                    slug
                    order
                    name
                    isActive
                }
                translations {
                    key
                    value
                    language
                }
            }
            headline
            metatags {
                key
                value
            }
            translations {
                key
                value
                language
            }
            mainImage {
                path
                fullPath
            }

            image {
                path
                fullPath
            }
        }
    }
`;
