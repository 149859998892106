import React, {
    memo,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from "react";
import Spinner from "@components/shared/spinner";
import {
    FormActionTypes,
    FormSelect,
    ItemOption,
    LookupOrVariationItem,
} from "@genericTypes/sharedTypes";
import styles from "./style.module.scss";
import {
    // newFieldAtom,
    addFieldAtom,
    formsAtom,
    removeErrorByCodeName,
    fetchDataHandlerAtom,
    selectLoadingHandler,
} from "@store/formStore";
import { useAtom } from "jotai";
import { AllFieldsDependency, AllForms } from "@genericTypes/form-store-types";
import { QueryClient } from "@tanstack/react-query";
import {
    GQLAllVariationsQuery,
    GQLAllVariationsQueryVariables,
    GQLVariations,
} from "@gql/types/gql";
import { ALL_VARIATIONS } from "@gql/queries/allVariations";
import { fetchDataFromFront } from "@gql/graphQLRequestServer";
import { getFromStorage } from "@helpers/storageHelper";
import { useDispatch, useFormState } from "../formReducer/FormReducer";
import { DEFAULT_OPTIONS } from "@lib/reactQueryDefaultOptions";
export interface mappedField {
    codeName: string;
    type: string;
    value: string | string[] | number;
}

const FormSelect = ({
    field,
    disabled,
    multiple,
    formId,
    register,
    setFieldChanged,
    isCalculatedYears,
    questionAsTitle,
    isFirstStep,
}: FormSelect): ReactElement => {
    const [selectedOption, setSelectedOption] = useState({
        label: "",
        selectedValue: "",
    });
    const [options, setOptions] = useState<
        LookupOrVariationItem[] | ItemOption[] | undefined
    >(
        isCalculatedYears
            ? gettingArrayFromMaxMinValues()
            : field?.defaultValues?.options ??
                  field.origin.defaultValues?.options ??
                  [],
    );

    const [, setForm] = useAtom(addFieldAtom);
    const [form] = useAtom<AllForms>(formsAtom);
    const [loading, setLoading] = useAtom(selectLoadingHandler);
    const [, removeError] = useAtom(removeErrorByCodeName);
    const [dependencies, fetchData] = useAtom(fetchDataHandlerAtom);

    const dispatch = useDispatch();
    const [formState] = useFormState();
    const { formErrors, modalVisibility } = formState;

    function gettingArrayFromMaxMinValues(): ItemOption[] {
        const today = new Date();
        const year = today.getFullYear();
        const newArray: ItemOption[] = [];
        for (
            let i: number = parseInt(
                (field.minValue?.value as string) ??
                    field.origin.minValue?.value,
                10,
            );
            i <=
            parseInt(
                (field.maxValue?.value as string) ??
                    field.origin.maxValue?.value,
                10,
            );
            i++
        ) {
            newArray.unshift({
                label: year - i,
                value: year - i,
                icon: null,
                id: year - i,
            });
        }
        if (
            field?.origin.meta?.[0]?.reverseOptions === "true" ||
            field?.meta?.[0]?.reverseOptions === "true"
        ) {
            return newArray.reverse();
        }

        return newArray;
    }
    const queryClient = new QueryClient(DEFAULT_OPTIONS);

    const change = async (e: FormEvent<HTMLSelectElement>) => {
        const { value, id } = e.currentTarget.selectedOptions[0];
        const { innerHTML: label } = e.currentTarget.selectedOptions[0];
        // if field has data dependency -> disable submit btn until the component finishes cleaning the data
        if (field?.dataDependency && field?.dataDependency?.dependency)
            (
                document.getElementById("submitBtn") as HTMLButtonElement
            ).disabled = true;
        setSelectedOption({
            selectedValue: value,
            label: label,
        });
        setFieldChanged(true);
        setLoading({
            codeName: field.origin.codeName,
            loading: true,
            id: id,
            value: value,
            displayLabel: label,
        });
        removeError(field.origin.codeName);
        dispatch({
            type: FormActionTypes.SET_ERRORS,
            payload: {},
        });
        setForm({
            codeName: field.origin.codeName,
            value: value as unknown as number,
            formId: formId,
            displayLabel: label,
            id: id,
            type: "select",
            options: [],
        });
        try {
            await fetchData({
                codeName: field.origin.codeName,
                formId: formId,
                id: id,
                value: value,
            });
        } catch (error) {
            (
                document.getElementById("submitBtn") as HTMLButtonElement
            ).disabled = false;
        }
        setLoading({
            codeName: field.origin.codeName,
            loading: false,
            id: id,
            value: value,
            displayLabel: label,
        });
        if (
            (field?.dataDependency && field?.dataDependency?.dependency) ||
            (field.origin.dataDependency && field.dataDependency?.dependency)
        ) {
            (
                document.getElementById("submitBtn") as HTMLButtonElement
            ).disabled = false;
        }
    };

    const onLoad = async () => {
        // check if global form state has the specified object and load it on UI
        if (
            form &&
            form[formId] &&
            form[formId].data?.[field.origin.codeName]
        ) {
            setSelectedOption({
                selectedValue: form[formId].data[field.origin.codeName]
                    .value as string,
                label: form[formId].data[field.origin.codeName]
                    .displayLabel as string,
            });
            setTimeout(async () => {
                try {
                    await fetchData({
                        codeName: field.origin.codeName,
                        formId: formId,
                        value: form[formId].data[field.origin.codeName]
                            .value as string,
                        id: form[formId].data[field.origin.codeName].id,
                    });
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log("error");
                }
            }, 200);
        } else {
            setSelectedOption({
                selectedValue: "",
                label: "",
            });
        }
        // for the first field in data dependency
        if (field.dataDependency || field.origin.dataDependency) {
            if (isFirstField()) {
                const optionsResponse = await queryClient.fetchQuery<
                    GQLVariations[]
                >({
                    queryKey: ["options"],
                    queryFn: async () => {
                        const data = await fetchDataFromFront<
                            GQLAllVariationsQuery,
                            GQLAllVariationsQueryVariables
                        >(ALL_VARIATIONS, {
                            type:
                                field.dataDependency?.type ??
                                field.origin.dataDependency?.type,
                            //@ts-ignore
                            orderByDesc:
                                field.dataDependency?.ordering?.field ??
                                field.origin.dataDependency?.ordering?.field,
                        })();
                        return data.allVariations as GQLVariations[];
                    },
                });
                setOptions(optionsResponse as LookupOrVariationItem[]);
                return;
            }
        }
    };

    function isFirstField() {
        if (field.dataDependency && !field.dataDependency?.dependency)
            return true;
        else if (
            field.origin.dataDependency &&
            !field.origin.dataDependency?.dependency
        )
            return true;
        return false;
    }

    useEffect(() => {
        void onLoad();
    }, []);

    const renderOptions = () => {
        let dependencyOptions = dependencies?.[field.origin.codeName]?.options;
        if (!dependencies?.[field.origin.codeName]?.options?.length) {
            const dependenciesFromStorage = getFromStorage(
                "local",
                "dependencies",
            );
            if (dependenciesFromStorage && dependenciesFromStorage.length) {
                const decodedDependencies = JSON.parse(
                    dependenciesFromStorage,
                ) as AllFieldsDependency;
                dependencyOptions =
                    decodedDependencies[field.origin.codeName]?.options;
            }
        }
        return options?.length
            ? options?.map((option: ItemOption, index: number) => (
                  <option
                      value={option.value}
                      key={`${option.value}-${index}`}
                      id={option.id?.toString()}
                  >
                      {option.label}
                  </option>
              ))
            : dependencyOptions?.map((option: ItemOption, index: number) => (
                  <option
                      value={option.value}
                      key={`${option.value}-${index}`}
                      id={option.id?.toString()}
                  >
                      {option.label}
                  </option>
              ));
    };

    function getDisplayLabel() {
        if (loading?.[field.origin.codeName])
            return loading?.[field.origin.codeName]?.displayLabel === "" ||
                !loading?.[field.origin.codeName]?.displayLabel
                ? field.placeholder ?? field.origin.placeholder
                : loading?.[field.origin.codeName]?.displayLabel;
        return (
            (form?.[formId]?.data?.[field.origin.codeName]?.displayLabel ||
                selectedOption.label ||
                field.placeholder) ??
            field.origin.placeholder
        );
    }

    function getSelectedValue() {
        if (loading?.[field.origin.codeName])
            return loading?.[field.origin.codeName]?.value;
        return (
            (form?.[formId]?.data?.[field.origin.codeName]?.value as string) ||
            selectedOption.selectedValue ||
            ""
        );
    }
    useEffect(() => {
        if (!modalVisibility) void onLoad();
    }, [modalVisibility]);

    return (
        <div className="mb-4 w-full">
            <div
                className={
                    isFirstStep
                        ? "mb-3 !font-extrabold text-center text-lg"
                        : questionAsTitle
                        ? `text-[23px] font-extrabold mb-[2rem] flex justify-center`
                        : `mb-3 flex`
                }
            >
                <label>
                    {field?.label ??
                        field.origin.label ??
                        field?.niceName ??
                        field.origin.niceName}
                </label>
            </div>
            <div className={`${styles.select} relative pb-4`}>
                <div
                    className={`rounded-lg absolute w-full text-base flex items-center  justify-between px-5 ${
                        styles.placeholder
                    } ${
                        disabled
                            ? styles.disabled
                            : formErrors && formErrors[field.origin.codeName]
                            ? styles.error
                            : styles.active
                    }`}
                >
                    <div>{getDisplayLabel()}</div>
                    {loading?.[field.origin.codeName]?.loading ? (
                        <Spinner size={30} color="#107fd4" success={false} />
                    ) : (
                        <i
                            className={`${styles.arrow} ${styles["arrow-down"]} `}
                        />
                    )}
                </div>
                <select
                    {...(register
                        ? {
                              ...register(field.origin.codeName as never),
                              onChange: change,
                          }
                        : null)}
                    disabled={loading?.[field.origin.codeName]?.loading}
                    multiple={multiple ?? false}
                    className={`w-full text-base absolute`}
                    value={getSelectedValue()}
                    name={field.origin.codeName}
                >
                    <option value="-1" id="-1" key="-1">
                        {field.placeholder ?? field.origin.placeholder}
                    </option>
                    {renderOptions()}
                </select>
            </div>
            {field?.note && <div className="flex">{field?.note}</div>}
            {formErrors &&
                formErrors[field.origin.codeName] &&
                formErrors[field.origin.codeName]?.message && (
                    <span
                        style={{
                            display: "flex",
                            color: "red",
                            fontSize: "12px",
                        }}
                    >
                        {formErrors[field.origin.codeName]?.message ??
                            "Field is required"}
                    </span>
                )}
        </div>
    );
};
function areEqual(
    prevState: Readonly<FormSelect>,
    nextState: Readonly<FormSelect>,
): boolean {
    return (
        prevState?.field?.origin.codeName === nextState?.field?.origin.codeName
    );
}

export default memo(FormSelect, areEqual);
