import { gql } from "graphql-request";
export const ALL_NEWS = gql`
    query allNews($limit: [Int], $offset: [Int], $domainId: Int, $typeId: Int) {
        allPosts(
            limit: $limit
            offset: $offset
            typeId: $typeId
            published: true
            isDeleted: false
            domainId: $domainId
            orderByDesc: createdAt
        ) {
            id
            title
            translations {
                key
                value
                language
            }
            image {
                fullPath
            }
            tags {
                name
            }
            url {
                id
                title
                language
                isActive
                url
                isMain
                main {
                    url
                }
            }
        }
    }
`;
