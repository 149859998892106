import { gql } from "graphql-request";
export const EXIT_MODAL_OFFERS_QUERY = gql`
    query getExitModalOffers(
        $categoryId: Int
        $language: String!
        $domainId: Int
    ) {
        allDomainOffers(
            domainId: $domainId
            categoryId: $categoryId
            orderByDesc: weight
        ) {
            placements
            offers(isDeleted: false, isActive: true) {
                languages
                title
                slug
                url(isActive: true, isDeleted: false, languageEq: $language) {
                    isMain
                    main {
                        url
                    }
                    url
                }
                translations {
                    key
                    value
                    language
                }
                logo {
                    path
                    fullPath
                }
                features(languageEq: $language) {
                    id
                    type
                    description
                    translations {
                        key
                        value
                        language
                    }
                }
            }
        }
    }
`;
