import styles from "./style.module.scss";
import { GQLOffers } from "@gql/types/gql";
import Image from "next/legacy/image";
import { PLACE_HOLDER_IMAGE_PATH } from "@lib/sharedUtils";

import OfferLinks from "@components/shared/offerLinks";

export default function TopBuyerItem({
    vendor,
    featured,
    categoryId,
    hideReview = false,
    imageClassName = "",
    containerStyle = "",
}: {
    vendor: GQLOffers;
    featured: boolean;
    categoryId: number;
    hideReview?: boolean;
    imageClassName?: string;
    containerStyle?: string;
}) {
    return (
        <span
            className={`${
                containerStyle
                    ? containerStyle
                    : "lg:w-[280px] lg:h-[212px] w-[255px] h-[200px]"
            } flex flex-col items-center rounded-lg justify-evenly ${
                styles["oneBuyer"]
            }  ${featured ? "border-2 border-[#F9BB21]" : ""}`}
        >
            <span
                className={`relative block ${styles["topBuyerImage"]} ${imageClassName} mt-1  h-[110px]`}
            >
                {vendor?.logo?.fullPath ? (
                    <img
                        src={vendor?.logo?.fullPath}
                        alt={
                            (vendor?.title as string) ??
                            (vendor?.slug as string)
                        }
                        className="max-h-[110px] max-w-[140px] w-full  relative"
                    />
                ) : (
                    <>
                        <Image
                            src={PLACE_HOLDER_IMAGE_PATH}
                            alt="logo"
                            objectFit="contain"
                            width={150}
                            height={40}
                        />
                    </>
                )}
            </span>

            {/*
                            Check if Vendor has a link 
                            then show [visit website]
                            else should display [read review] link
                         */}
            <div className="lg:w-[80%] w-[80%] flex flex-col gap-2">
                <OfferLinks
                    vendor={vendor}
                    categoryId={categoryId}
                    classNameButton={styles["buttonStyle"]}
                    oneLine={true}
                    vendorShow={hideReview}
                />
            </div>
        </span>
    );
}
