import { gql } from "graphql-request";
export const CATEGORY_BY_ID = gql`
    query categoryById($id: Int, $domainId: Int) {
        domainForm(
            domainId: $domainId
            categoryId: $id
            isDeleted: false
            isActive: true
        ) {
            id
            form(isVariant: false) {
                id
                stepToSendingData
                title
                description
                successMessage
                errorMessage
                translations {
                    key
                    value
                    language
                }
                steps {
                    id
                    title
                    description
                    order
                    adminTitle
                    buttonText
                    includeTCPAMessage
                    translations {
                        key
                        value
                        language
                    }
                    fields {
                        id
                        fieldId
                        translations {
                            key
                            value
                            language
                        }
                        order
                        stepId
                        order
                        label
                        isRequired
                        placeholder
                        note
                        autocomplete
                        defaultValue
                        pattern
                        mask
                        title
                        niceName
                        meta
                        dataDependency
                        conditionalLogic
                        errorMessage
                        minValue
                        maxValue
                        defaultValues
                        origin {
                            codeName
                            fieldType
                            validationType
                            id
                            label
                            placeholder
                            note
                            autocomplete
                            defaultValue
                            translations {
                                key
                                value
                                language
                            }
                            pattern
                            mask
                            title
                            niceName
                            meta
                            dataDependency
                            errorMessage
                            minValue
                            maxValue
                            defaultValues
                        }
                    }
                }
            }
        }
    }
`;
