import { getUserMeta } from "@helpers/user-meta";
import {
    formIdAtom,
    formJsonAtom,
    formsAtom,
    leadIdAtom,
    otpCodeAtom,
    showErrorsFormAtom,
    showOtpAtom,
} from "@store/formStore";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import OtpInput from "react18-input-otp";
import EditPhoneNumber from "./editPhoneNumber";
import styles from "../../modal/formModal/styles.module.scss";
import { submitLeadForm } from "src/api/submitLead";
import { FormActionTypes } from "@genericTypes/sharedTypes";
import { useDispatch } from "../formReducer/FormReducer";

const Otp = ({
    timer,
    originalTime,
    setTimer,
    showOtpError,
    setShowOtpError,
}: {
    timer: number;
    setTimer: React.Dispatch<React.SetStateAction<number>>;
    originalTime: number;
    showOtpError: boolean;
    setShowOtpError: (showOtp: boolean) => void;
}) => {
    const [form] = useAtom(formsAtom);
    const [formId] = useAtom(formIdAtom);
    const [leadId] = useAtom(leadIdAtom);
    const [formJson] = useAtom(formJsonAtom);
    const [, setShowOtp] = useAtom(showOtpAtom);
    const [, setShowInvalidFieldsFrom] = useAtom(showErrorsFormAtom);

    const dispatch = useDispatch();

    const [showTimer, setShowTimer] = useState(true);
    const [showEditPhoneNumber, setShowEditPhoneNumber] = useState(false);
    const [otpCode, setOtpCode] = useAtom(otpCodeAtom);

    const submitOtp = async (shouldValidate: boolean): Promise<void> => {
        const meta = getUserMeta();
        setShowOtpError(false);
        const fieldsThatHaveValue: { codeName: string; value: string }[] =
            Object.values(form?.[formId]?.data || {})
                .filter((item) => item.value !== "")
                .map((field) => {
                    return {
                        codeName: field.codeName,
                        value: field.value as string,
                    };
                });
        const { data, error } = await submitLeadForm({
            fields: fieldsThatHaveValue,
            leadId,
            jornayaId:
                (
                    document?.getElementById(
                        "leadid_token",
                    ) as HTMLInputElement | null
                )?.value ?? undefined,
            url: window.location.href,
            submit: true && shouldValidate,
            domainFormId: formJson?.domainFormId as number,
            otpCode: otpCode || undefined,
            language: "en",
            //@ts-ignore
            meta,
        });
        //@ts-ignore
        if (!shouldValidate) {
            setShowOtpError(false);
            return;
        }
        //@ts-ignore
        if (data?.errorCode === "INVALID_OTP_VALIDATION" || error) {
            setShowOtpError(true);
            return;
        } else if (!data?.otpRequired) {
            dispatch({
                type: FormActionTypes.SET_SHOW_THANK_YOU,
                payload: true,
            });
            setShowOtp(false);
            setShowInvalidFieldsFrom(false);
        }
    };

    const handleChange = (enteredOtp: string) => {
        setShowOtpError(false);
        setOtpCode(enteredOtp);
    };

    const timerChange = () => {
        setTimeout(() => {
            decreaseOneSecond();
        }, 1000);
    };

    const decreaseOneSecond = () => {
        setTimer(timer - 1);
    };

    useEffect(() => {
        timerChange();
    }, []);

    useEffect(() => {
        if (timer > 0) timerChange();
        else {
            setShowTimer(false);
        }
    }, [timer]);

    const resetTimer = async () => {
        await submitOtp(false);
        setTimer(originalTime);
        setShowTimer(true);
    };

    return (
        <div className="flex flex-col gap-8 max-w-[600px] m-auto sm:w-full">
            {showEditPhoneNumber ? (
                <EditPhoneNumber
                    setShowEditPhoneNumber={setShowEditPhoneNumber}
                    resetTimer={resetTimer}
                />
            ) : (
                <>
                    <div className="flex flex-col gap-2 w-full">
                        <p className="text-gray-400 p-0 m-0">
                            Verification Code
                        </p>
                        <h1 className="font-bold">
                            We need to verify your phone number&nbsp;
                            <span>
                                {form[formId]?.data["phoneNumber"]?.value ?? ""}
                            </span>
                            ,&nbsp;sending you the code now
                        </h1>
                    </div>
                    <OtpInput
                        value={otpCode}
                        onChange={handleChange}
                        numInputs={
                            process.env
                                .NUMBER_OF_OTP_DIGITS as unknown as number
                        }
                        containerStyle={"flex gap-2 flex-grow w-full"}
                        inputStyle={`${styles["otp-input"]} shadow`}
                        isInputNum
                        shouldAutoFocus
                    />
                    {showOtpError && (
                        <span
                            style={{
                                color: "red",
                                display: "flex",
                                alignSelf: "center",
                            }}
                        >
                            Invalid OTP code
                        </span>
                    )}
                    {showTimer ? (
                        <div>
                            <p>
                                You can send another verification code in&nbsp;
                                <span className="font-bold text-[#1692f09d]">
                                    {timer}
                                </span>
                            </p>
                        </div>
                    ) : (
                        <div className="flex flex-col gap-2 w-full">
                            <p className="w-fit text-gray-400">
                                Didn't get the verification code?
                            </p>
                            <p
                                onClick={() => resetTimer()}
                                className="w-fit text-gray-500 cursor-pointer"
                            >
                                Resend Code
                            </p>
                        </div>
                    )}
                    <div>
                        <p
                            onClick={() => setShowEditPhoneNumber(true)}
                            className="w-fit font-bold text-[#1693F0] underline cursor-pointer"
                        >
                            Edit Phone Number
                        </p>
                    </div>
                    <div>
                        <button
                            onClick={() => submitOtp(true)}
                            className={`${styles["btn"]} shadow`}
                        >
                            Verify
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Otp;
