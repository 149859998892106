import { gql } from "graphql-request";
export const REVIEW_GET_DOMAIN_OFFER_QUERY = gql`
    query getReviewDomainOffers(
        $categoryId: Int
        $language: String!
        $domainId: Int
    ) {
        allDomainOffers(domainId: $domainId, categoryId: $categoryId) {
            placements
            offers(orderByDesc: weight, isDeleted: false, isActive: true) {
                id
                slug
                title
                features(languageEq: $language) {
                    id
                    type
                    description
                    translations {
                        key
                        value
                        language
                    }
                }
                url(isActive: true, isDeleted: false, languageEq: $language) {
                    isMain
                    main {
                        url
                    }
                    url
                }
                translations {
                    key
                    value
                    language
                }
                logo {
                    id
                    path
                    fullPath
                }
            }
        }
    }
`;
