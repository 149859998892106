import { gql } from "graphql-request";

export const CATEGORY_AND_OFFER_DROPDOWN = gql`
    query categoryAndOfferDropDown($domainId: Int) {
        domain(id: $domainId) {
            categories(
                limit: 100
                orderBy: createdAt
                isPublished: true
                isDeleted: false
            ) {
                id
                name
                slug
                label
                parentId
                url(isActive: true, isDeleted: false) {
                    isMain
                    main {
                        url
                    }
                    url
                }
                translations {
                    key
                    value
                    language
                }
                offers(isActive: true, isDeleted: false, bodyIsNull: false) {
                    id
                    title
                    name
                    slug
                    url(isActive: true, isDeleted: false) {
                        isMain
                        main {
                            url
                        }
                        url
                    }
                    translations {
                        key
                        value
                        language
                    }
                    logo {
                        fullPath
                    }
                }
            }
        }
    }
`;
