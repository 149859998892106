import { gql } from "graphql-request";
export const TRENDING_NEWS = gql`
    query trendingNews($domainId: Int) {
        allPosts(
            limit: 6
            offset: 46
            published: true
            isDeleted: false
            typeId: 2296
            featured: true
            orderByDesc: createdAt
            domainId: $domainId
        ) {
            title
            image {
                fullPath
                path
            }
            url {
                isActive
                url
                isMain
                main {
                    url
                }
            }
            tags {
                name
            }
        }
    }
`;
