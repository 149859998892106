import { gql } from "graphql-request";
export const VENDOR_COMPARE_GET_VENDORS_TO_COMPARE_QUERY = gql`
    query vendorCompareGetVendorsToCompare($domainId: Int, $categoryId: Int) {
        allDomainOffers(domainId: $domainId, categoryId: $categoryId) {
            offers(orderByDesc: weight, isDeleted: false, isActive: true) {
                slug
                translations {
                    key
                    value
                    language
                }
                logo {
                    fullPath
                }
            }
        }
    }
`;
