import { GraphQLClient } from "graphql-request";

export const graphQLRequestServer = new GraphQLClient(
    `${process.env.NEXT_PUBLIC_GRAPHQL_URL as string}&apiKey=${
        process.env.NEXT_PUBLIC_API_KEY as string
    }`,
);
export const graphQLRequestClient = new GraphQLClient(`/api/getData`);

const headers: RequestInit["headers"] = {
    "X-language": process.env.NEXT_PUBLIC_DEFAULT_LANG as string,
    "X-DOMAIN-NAME": process.env.NEXT_PUBLIC_X_DOMAIN as string,
};

export const fetchData = <TData, TVariables>(
    query: string,
    variables?: TVariables,
    skipCache: boolean = false,
    options?: RequestInit["headers"],
): (() => Promise<TData>) => {
    return async () => {
        let queryString: Awaited<TData>;
        try {
            //@ts-ignore
            queryString = await graphQLRequestServer.request(query, variables, {
                ...options,
                ...{
                    ...headers,
                    cache: skipCache ? "no-store" : "force-cache",
                },
            });
            return queryString;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log("error==>", query);
            // eslint-disable-next-line no-console
            console.log("error==>", error);
            //@ts-ignore
            return queryString;
        }
    };
};

export const fetchDataFromFront = <TData, TVariables>(
    query: string,
    variables?: TVariables,
    options?: RequestInit["headers"],
): (() => Promise<TData>) => {
    return async () => {
        //@ts-ignore
        return await graphQLRequestClient.request(query, variables, {
            ...options,
        });
    };
};

export const fetchHandler = <TData, TVariables>(
    query: string,
    variables?: TVariables,
    skipCache: boolean = false,
): (() => Promise<TData>) => {
    return async () => {
        let queryString: Awaited<TData>;
        try {
            //@ts-ignore
            return fetch(
                `${process.env.NEXT_PUBLIC_GRAPHQL_URL as string}&apiKey=${
                    process.env.NEXT_PUBLIC_API_KEY as string
                }`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-language": process.env
                            .NEXT_PUBLIC_DEFAULT_LANG as string,
                        "X-DOMAIN-NAME": process.env
                            .NEXT_PUBLIC_X_DOMAIN as string,
                    },
                    body: JSON.stringify({
                        query: query,
                        variables: {
                            language: process.env.NEXT_PUBLIC_DEFAULT_LANG,
                            ...(variables ? variables : {}),
                        },
                    }),
                    cache: skipCache ? "no-store" : "force-cache",
                    ...(skipCache ? {} : { next: { revalidate: 2000000 } }),
                },
            ).then((response) => {
                return response.json();
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log("error==>", query);
            // eslint-disable-next-line no-console
            console.log("error==>", error);
            //@ts-ignore
            return queryString;
        }
    };
};
