export const handleFieldMasking = (
    mask: "us-phone" | "zipCode",
    value: string,
): string => {
    const handleUsPhoneNumberMasking = () => {
        const cleaned = value.replace(/[^0-9]/g, "");

        if (cleaned[0] === "1" && cleaned[1] === "1") {
            const x =
                cleaned.match(
                    /([1]{0,1})(1{0,1})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})/,
                ) ?? "";

            return (
                (x[1] ? "1" : "") +
                (x[3].length >= 1 ? (x[1] ? " (" : "(") : "") +
                (x[3] ? x[3] : "") +
                (x[4].length >= 1 ? ") " : "") +
                (x[4] ? x[4] : "") +
                (x[5].length >= 1 ? "-" : "") +
                (x[5] ? x[5] : "")
            );
        } else {
            const x =
                cleaned.match(
                    /([1]{0,1})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})/,
                ) ?? "";

            return (
                (x[1] ? "1" : "") +
                (x[2].length >= 1 ? (x[1] ? " (" : "(") : "") +
                (x[2] ? x[2] : "") +
                (x[3].length >= 1 ? ") " : "") +
                (x[3] ? x[3] : "") +
                (x[4].length >= 1 ? "-" : "") +
                (x[4] ? x[4] : "")
            );
        }
    };

    const handleZipCodeMasking = () => {
        const zipCodeAllowedCharacters = value.replace(/[^0-9\-]/g, "");
        const lastIndexOf = zipCodeAllowedCharacters.lastIndexOf("-");

        if (lastIndexOf != 5 && lastIndexOf != -1) {
            return zipCodeAllowedCharacters.substring(0, lastIndexOf);
        }

        const matchedPattern = zipCodeAllowedCharacters.match(
            /(^[0-9]+$)|(^[0-9]{5}-[0-9]{4,}$)/,
        );

        if (matchedPattern) {
            const dashIndexOf = matchedPattern[0].indexOf("-");
            const part2StartIndx = 5;
            const part1String = matchedPattern[0].substring(0, 5);
            const part2String = matchedPattern[0].substring(part2StartIndx, 10);

            return `${part1String}${
                part2String.length && dashIndexOf < 0 ? "-" : ""
            }${part2String}`;
        }
        return zipCodeAllowedCharacters.substring(0, 10);
    };

    switch (mask) {
        case "us-phone":
            return handleUsPhoneNumberMasking();
        case "zipCode":
            return handleZipCodeMasking();
        default:
            return "";
    }
};
